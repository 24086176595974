"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pjaxService_1 = require("../services/pjaxService");
var loadScriptService_1 = require("../services/loadScriptService");
var getCurrentPage_1 = require("../utils/getCurrentPage");
var pageTypes_1 = require("../constants/pageTypes");
var ready_1 = require("../utils/ready");
/**
 * The purpose of this class is to complete the load the petals code. The rest of the petals relate code lives in ./petals.ts
 *
 * @export
 * @class McPetals3d
 */
var McPetals3d = /** @class */ (function () {
    function McPetals3d() {
        ready_1.default(loadPetals);
        pjaxService_1.default.addToPageViewEvents(loadPetals);
        function loadPetals() {
            return new Promise(function (resolve, reject) {
                var currentPage = getCurrentPage_1.default();
                if (currentPage === pageTypes_1.PAGE_TYPES.home || currentPage === pageTypes_1.PAGE_TYPES.explore) {
                    return loadScriptService_1.default.loadScripts([
                        "/assets/js/vendors~petals.js",
                        "/assets/js/createjs.js",
                        "/assets/js/Logo.js",
                        "/assets/js/Petals.js"
                    ]).then(function () {
                        resolve();
                    }).catch(function (error) {
                        reject(error);
                    });
                }
                else {
                    resolve("Petals not required");
                }
            });
        }
    }
    return McPetals3d;
}());
exports.default = McPetals3d;
