"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var resizeService_1 = require("../services/resizeService");
var mediaQueries_1 = require("../constants/mediaQueries");
var querySelectorAll_1 = require("../utils/querySelectorAll");
var ELEMENT_NAME = "text-revealer";
var TextRevealer = /** @class */ (function (_super) {
    __extends(TextRevealer, _super);
    function TextRevealer() {
        var _this = _super.call(this) || this;
        _this.isOpen = false;
        _this.recaleRequired = false;
        return _this;
    }
    TextRevealer.prototype.connectedCallback = function () {
        this.init();
    };
    TextRevealer.prototype.disconnectedCallback = function () {
        resizeService_1.default.remove(this.handleResizeEvent);
    };
    TextRevealer.prototype.init = function () {
        if (this.firstElementChild === null) {
            return;
        }
        var firstChildTag = this.firstElementChild.tagName;
        if (firstChildTag === "DIV" || firstChildTag === "SECTION" || firstChildTag === "SPAN") {
            this.textContainerEl = this.firstElementChild;
            var paragraphs = querySelectorAll_1.default("p", this.textContainerEl);
            // We only want to use the revealer if there are more than one paragraphs
            if (paragraphs.length > 1) {
                this.firstParagraph = paragraphs[0];
                this.expandedHeight = this.textContainerEl.getBoundingClientRect().height;
                this.collapedHeight = this.firstParagraph.getBoundingClientRect().height;
                Object.assign(this.textContainerEl.style, {
                    overflow: "hidden",
                    height: this.collapedHeight + "px"
                });
                this.toggleButton = this.createToggleButton();
                this.toggleButton.addEventListener("click", this.handleToggleClickEvent.bind(this));
                this.handleResizeEvent = this.handleResizeEvent.bind(this);
                resizeService_1.default.add(this.handleResizeEvent);
            }
        }
        else {
            return console.warn("First child element is a " + firstChildTag + " tag. Child element of text revealer must be a DIV, SECTION or SPAN element");
        }
    };
    TextRevealer.prototype.handleToggleClickEvent = function (event) {
        event.preventDefault();
        if (this.isOpen) {
            this.close();
            this.isOpen = false;
        }
        else {
            this.open();
            this.isOpen = true;
        }
    };
    TextRevealer.prototype.handleResizeEvent = function () {
        this.recaleRequired = true;
    };
    TextRevealer.prototype.open = function () {
        if (this.recaleRequired) {
            Object.assign(this.textContainerEl.style, {
                height: "",
            });
            this.setHeights();
            this.recaleRequired = false;
        }
        else {
            Object.assign(this.textContainerEl.style, {
                height: this.expandedHeight + "px",
                transition: window.matchMedia(mediaQueries_1.MEDIA_QUERIES.preferesReducedMotion).matches === false ? "height .4s ease-in-out" : ""
            });
        }
        this.toggleButton.innerHTML = this.createToggleInnerHTML("Read less");
    };
    TextRevealer.prototype.close = function () {
        if (this.recaleRequired) {
            this.setHeights();
            this.recaleRequired = false;
        }
        else {
            Object.assign(this.textContainerEl.style, {
                height: this.collapedHeight + "px",
                transition: window.matchMedia(mediaQueries_1.MEDIA_QUERIES.preferesReducedMotion).matches === false ? "height .3s ease-in-out" : ""
            });
        }
        this.toggleButton.innerHTML = this.createToggleInnerHTML("Read more");
    };
    TextRevealer.prototype.setHeights = function () {
        this.expandedHeight = this.textContainerEl.getBoundingClientRect().height;
        this.collapedHeight = this.firstParagraph.getBoundingClientRect().height;
    };
    TextRevealer.prototype.createToggleButton = function () {
        var buttonEl = document.createElement("mc-button");
        buttonEl.innerHTML = this.createToggleInnerHTML("Read more");
        this.appendChild(buttonEl);
        return buttonEl;
    };
    TextRevealer.prototype.createToggleInnerHTML = function (text) {
        return "\n\t\t\t<button class=\"button\">\n\t\t\t\t" + text + "\n\t\t\t\t<span class=\"button__bg\"></span>\n\t\t\t</button>";
    };
    return TextRevealer;
}(HTMLElement));
customElements.define(ELEMENT_NAME, TextRevealer);
