"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ancestorOrSelf_1 = require("../utils/ancestorOrSelf");
var ELEMENT_NAME = "share-links";
var ACTIVE_CLASS = ELEMENT_NAME + "--active";
var ShareLinks = /** @class */ (function (_super) {
    __extends(ShareLinks, _super);
    function ShareLinks() {
        var _this = _super.call(this) || this;
        _this.isOpen = false;
        return _this;
    }
    ShareLinks.prototype.connectedCallback = function () {
        this.init();
    };
    ShareLinks.prototype.disconnectedCallback = function () {
        document.documentElement.removeEventListener("click", this.handleDocumentElementClickEvent);
    };
    ShareLinks.prototype.init = function () {
        this.toggleEl = this.querySelector("." + ELEMENT_NAME + "__toggle");
        if (this.toggleEl !== null) {
            this.toggleEl.addEventListener("click", this.handleToggleClickEvent.bind(this));
            this.handleDocumentElementClickEvent = this.handleDocumentElementClickEvent.bind(this);
            document.documentElement.addEventListener("click", this.handleDocumentElementClickEvent);
        }
        else {
            console.warn("No toggle element found for share links");
        }
    };
    ShareLinks.prototype.handleToggleClickEvent = function () {
        this.toggleLinks();
    };
    ShareLinks.prototype.handleDocumentElementClickEvent = function (event) {
        var shareLinksIsTarget = ancestorOrSelf_1.default(event.target, "." + ELEMENT_NAME) !== null;
        if (shareLinksIsTarget === false) {
            this.closeLinks();
        }
    };
    ShareLinks.prototype.toggleLinks = function () {
        this.classList.toggle(ACTIVE_CLASS);
    };
    ShareLinks.prototype.closeLinks = function () {
        if (this.classList.contains(ACTIVE_CLASS) === true) {
            this.classList.remove(ACTIVE_CLASS);
        }
    };
    return ShareLinks;
}(HTMLElement));
customElements.define(ELEMENT_NAME, ShareLinks);
