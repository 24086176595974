"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getTransitionEvent() {
    var t;
    var el = document.createElement("fakeelement");
    var transitions = {
        "transition": "transitionend",
        "OTransition": "oTransitionEnd",
        "MozTransition": "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
    };
    for (t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
}
var transitionEvent;
function whichTransitionEvent() {
    if (transitionEvent === undefined) {
        transitionEvent = getTransitionEvent();
    }
    return transitionEvent;
}
exports.default = whichTransitionEvent;
