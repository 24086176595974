"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var gsap_1 = require("gsap");
var ELEMENT_NAME = "copy-link";
var CopyLink = /** @class */ (function (_super) {
    __extends(CopyLink, _super);
    function CopyLink() {
        var _this = _super.call(this) || this;
        _this.init();
        return _this;
    }
    CopyLink.prototype.init = function () {
        this.copyLinkElement = this.querySelector(".js-text");
        if (this.copyLinkElement === null) {
            console.warn("A p element containing the link is required for the copy-link element");
            return;
        }
        this.linkCopiedEl = this.createLinkCopiedEl();
        this.copyButton = this.createCopyButton();
        this.copyButton.addEventListener("click", this.handleCopyButtonClickEvent.bind(this));
        this.appendChild(this.copyButton);
    };
    CopyLink.prototype.handleCopyButtonClickEvent = function (event) {
        var _this = this;
        var textarea = document.createElement("textarea");
        textarea.classList.add("audio-only");
        textarea.setAttribute("readonly", "");
        textarea.value = this.copyLinkElement.value;
        this.appendChild(textarea);
        var selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
        textarea.select();
        document.execCommand("copy");
        this.appendChild(this.linkCopiedEl);
        var timeline = new gsap_1.TimelineLite({
            onComplete: function () {
                _this.linkCopiedEl.remove();
                textarea.remove();
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            }
        });
        timeline.to(this.linkCopiedEl, .3, { opacity: 1 });
        timeline.to(this.linkCopiedEl, .3, { opacity: 0, delay: 1.5 });
    };
    CopyLink.prototype.createLinkCopiedEl = function () {
        var linkCopiedEl = document.createElement("p");
        linkCopiedEl.innerText = "Link copied";
        Object.assign(linkCopiedEl.style, {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fecf12",
            padding: "1rem 2rem",
            margin: 0,
            boxShadow: "0 0 2rem 0.2rem rgba(55,55,55, .2)",
            zIndex: 2,
            opacity: 0
        });
        return linkCopiedEl;
    };
    CopyLink.prototype.displayLinkCopiedEl = function () {
    };
    CopyLink.prototype.createCopyButton = function () {
        var mcButton = document.createElement("mc-button");
        var button = document.createElement("button");
        button.className = "button copy-link__button";
        button.innerHTML = "\n\t\t\tCopy link\n\t\t\t<span class=\"button__bg\"></button>\n\t\t";
        mcButton.appendChild(button);
        return mcButton;
    };
    return CopyLink;
}(HTMLElement));
customElements.define(ELEMENT_NAME, CopyLink);
