"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var querySelectorAll_1 = require("../utils/querySelectorAll");
var ancestorOrSelf_1 = require("../utils/ancestorOrSelf");
var gsap_1 = require("gsap");
var AddMemory = /** @class */ (function (_super) {
    __extends(AddMemory, _super);
    function AddMemory() {
        var _this = _super.call(this) || this;
        _this.isAnimating = false;
        return _this;
    }
    AddMemory.prototype.connectedCallback = function () {
        var _this = this;
        this.addMemoryOptions = querySelectorAll_1.default("[add-memory-option]", this);
        this.addMemoryViews = querySelectorAll_1.default("[add-memory-view]", this);
        this.addMemoryOptions.forEach(function (option) {
            option.addEventListener("click", _this.handleAddMemoryOptionClickEvent.bind(_this));
        });
    };
    AddMemory.prototype.reset = function () {
        this.toggleElements(this.addMemoryViews[0], this.addMemoryViews);
    };
    AddMemory.prototype.handleAddMemoryOptionClickEvent = function (event) {
        var option = ancestorOrSelf_1.default(event.target, "[add-memory-option]");
        if (option !== null) {
            var optionValue = option.getAttribute("add-memory-option");
            var optionTarget = document.querySelector(optionValue);
            if (optionTarget !== null) {
                this.toggleElements(optionTarget, this.addMemoryViews);
            }
        }
    };
    AddMemory.prototype.toggleElements = function (elementToShow, elementsToHide) {
        var _this = this;
        if (this.isAnimating === false) {
            this.isAnimating = true;
            var tl = new gsap_1.TimelineLite({
                onComplete: function () {
                    _this.isAnimating = false;
                }
            });
            tl.to(elementsToHide, .5, {
                opacity: 0,
                onComplete: function () {
                    elementsToHide.forEach(function (element) {
                        if (element !== elementToShow) {
                            element.classList.remove(AddMemory.MEMORY_VIEW_ACTIVE);
                        }
                    });
                    elementToShow.style.opacity = "0";
                    elementToShow.classList.add(AddMemory.MEMORY_VIEW_ACTIVE);
                }
            });
            tl.to(elementToShow, .5, {
                opacity: 1
            });
        }
    };
    AddMemory.MEMORY_VIEW_ACTIVE = "add-memory__view--active";
    return AddMemory;
}(HTMLElement));
exports.AddMemory = AddMemory;
customElements.define("add-memory", AddMemory);
