"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var FormField = /** @class */ (function (_super) {
    __extends(FormField, _super);
    function FormField() {
        var _this = _super.call(this) || this;
        _this.setState = function (force) {
            if (force === void 0) { force = false; }
            var value = _this.input.value;
            if (force || value) {
                _this.classList.add(FormField.ACTIVE_CLASS);
            }
            else {
                _this.classList.remove(FormField.ACTIVE_CLASS);
            }
        };
        _this.setStateHandler = function () {
            _this.setState();
        };
        _this.setStateForceHandler = function () {
            _this.setState(true);
        };
        return _this;
    }
    FormField.prototype.connectedCallback = function () {
        this.input = this.querySelector("input:not([type=\"hidden\"]), textarea");
        this.input.addEventListener("focus", this.setStateForceHandler);
        this.input.addEventListener("change", this.setStateHandler);
        this.input.addEventListener("blur", this.setStateHandler);
        this.setStateHandler();
        this.labelError = this.querySelector(".form-field__label-error");
    };
    FormField.prototype.validate = function () {
        var isValid = this.validateInput();
        if (isValid) {
            this.classList.remove(FormField.INVALID_CLASS);
            if (this.labelError !== null) {
                this.labelError.setAttribute("aria-hidden", "true");
            }
        }
        else {
            this.classList.add(FormField.INVALID_CLASS);
            if (this.labelError !== null) {
                this.labelError.removeAttribute("aria-hidden");
            }
        }
        return isValid;
    };
    FormField.prototype.focus = function () {
        if (this.input !== null) {
            this.input.focus();
        }
    };
    FormField.prototype.validateInput = function () {
        switch (this.input.nodeName) {
            case "INPUT":
                return this.input.checkValidity();
            case "TEXTAREA":
                return this.input.checkValidity();
        }
    };
    FormField.ACTIVE_CLASS = "form-field--active";
    FormField.INVALID_CLASS = "form-field--invalid";
    return FormField;
}(HTMLElement));
exports.FormField = FormField;
customElements.define("form-field", FormField);
