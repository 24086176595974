"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var gsap_1 = require("gsap");
require("gsap/CSSPlugin");
var SwupPreloadPlugin = require("@swup/preload-plugin/dist/SwupPreloadPlugin.min.js");
var SwupScrollPlugin = require("@swup/scroll-plugin/dist/SwupScrollPlugin.min.js");
var SwupJsPlugin = require("@swup/js-plugin/dist/SwupJsPlugin.min.js");
var SwupDebugPlugin = require("@swup/debug-plugin/dist/SwupDebugPlugin.min.js");
var SwupBodyClassPlugin = require("@swup/body-class-plugin/dist/SwupBodyClassPlugin.min.js");
var SWUP_MAIN = "#swup-main";
var SWUP_HEADER_NAV = "#swup-header-nav";
var SWUP_FOOTER_NAV = "#swup-footer-nav";
var SWUP_SKIP_LINKS = "#swup-skip-links";
var SWUP_TRANSITIONS = [
    // Default page transitions
    {
        from: "(.*)",
        to: "(.*)",
        in: function (next) {
            var swupMainEl = document.querySelector(SWUP_MAIN);
            var headerNavEl = document.querySelector(SWUP_HEADER_NAV);
            var headerNavPlaceholderEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav-placeholder") : null;
            var headerNavListEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav") : null;
            var tl = new gsap_1.TimelineLite({
                onComplete: next
            });
            tl.fromTo(swupMainEl, .8, { opacity: 0 }, { opacity: 1, clearProps: "all" }), 0;
            if (headerNavPlaceholderEl !== null) {
                tl.fromTo(headerNavPlaceholderEl, .8, { opacity: 0 }, { scaleX: 1, opacity: 1, ease: gsap_1.Sine.easeOut, clearProps: "all" }, 0);
            }
            if (headerNavListEl !== null) {
                tl.fromTo(headerNavListEl, .8, { opacity: 0 }, { opacity: 1, ease: gsap_1.Sine.easeOut, clearProps: "all" }, 0);
            }
        },
        out: function (next) {
            var swupMainEl = document.querySelector(SWUP_MAIN);
            var headerNavEl = document.querySelector(SWUP_HEADER_NAV);
            var headerNavPlaceholderEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav-placeholder") : null;
            var headerNavListEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav") : null;
            var tl = new gsap_1.TimelineLite({
                onComplete: next
            });
            tl.fromTo(swupMainEl, .5, { opacity: 1 }, { opacity: 0 }, 0);
            if (headerNavPlaceholderEl !== null) {
                tl.fromTo(headerNavPlaceholderEl, .5, { opacity: 1 }, { opacity: 0, ease: gsap_1.Sine.easeOut }, 0);
            }
            if (headerNavListEl !== null) {
                tl.fromTo(headerNavListEl, .5, { opacity: 1 }, { opacity: 0, ease: gsap_1.Sine.easeOut }, 0);
            }
        }
    },
    {
        from: "/explore",
        to: "/explore/:id",
        in: function (next) {
            var swupMainEl = document.querySelector(SWUP_MAIN);
            var headerNavEl = document.querySelector(SWUP_HEADER_NAV);
            var headerNavPlaceholderEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav-placeholder") : null;
            var headerNavListEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav") : null;
            var tl = new gsap_1.TimelineLite({
                onComplete: next
            });
            tl.fromTo(swupMainEl, .8, { opacity: 0 }, { opacity: 1, clearProps: "all", ease: gsap_1.Sine.easeInOut }, 0);
            if (headerNavPlaceholderEl !== null) {
                tl.fromTo(headerNavPlaceholderEl, .8, { opacity: 0 }, { scaleX: 1, opacity: 1, ease: gsap_1.Sine.easeOut, clearProps: "all" }, 0);
            }
            if (headerNavListEl !== null) {
                tl.fromTo(headerNavListEl, .8, { opacity: 0 }, { opacity: 1, ease: gsap_1.Sine.easeOut, clearProps: "all" }, 0);
            }
        },
        out: function (next) {
            var swupMainEl = document.querySelector(SWUP_MAIN);
            var headerNavEl = document.querySelector(SWUP_HEADER_NAV);
            var headerNavPlaceholderEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav-placeholder") : null;
            var headerNavListEl = headerNavEl !== null ? headerNavEl.querySelector(".site-header__nav") : null;
            var tl = new gsap_1.TimelineLite({
                onComplete: next
            });
            tl.fromTo(swupMainEl, 0, { opacity: 1 }, { opacity: 0 }, 0);
            if (headerNavPlaceholderEl !== null) {
                tl.fromTo(headerNavPlaceholderEl, 0, { opacity: 1 }, { opacity: 0, ease: gsap_1.Sine.easeOut }, 0);
            }
            if (headerNavListEl !== null) {
                tl.fromTo(headerNavListEl, 0, { opacity: 1 }, { opacity: 0, ease: gsap_1.Sine.easeOut }, 0);
            }
        }
    }
];
exports.SWUP_OPTIONS = {
    containers: [SWUP_MAIN, SWUP_HEADER_NAV, SWUP_FOOTER_NAV, SWUP_SKIP_LINKS],
    // linkSelector: `a[href^="${window.location.origin}"]:not([data-no-swup]):not([target="_blank"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]), a[href^="#"]:not([data-no-swup]):not([target="_blank"])`, // For Umbraco site
    linkSelector: "a[href^=\"" + window.location.origin + "\"]:not([data-no-swup]):not([target=\"_blank\"]), a[href^=\"/\"]:not([data-no-swup]):not([target=\"_blank\"]), a[href^=\"#\"]:not([data-no-swup]):not([target=\"_blank\"]), a[href$=\".html\"]:not([data-no-swup]):not([target=\"_blank\"])",
    // cache: false,
    animateHistoryBrowsing: true,
    plugins: [
        // new SwupDebugPlugin(),
        new SwupPreloadPlugin(),
        new SwupJsPlugin(SWUP_TRANSITIONS),
        new SwupScrollPlugin({
            animateScroll: false
        }),
        new SwupBodyClassPlugin()
    ]
};
