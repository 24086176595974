"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Rotator = /** @class */ (function () {
    function Rotator() {
        this.x = 0;
        this.y = 0;
        this.newX = 0;
        this.newY = 0;
        this.setNew = function (newX, newY) {
            this.newX = newX;
            this.newY = newY;
        };
        this.lerpToNew = function (amt) {
            if (amt === void 0) { amt = 0.1; }
            this.x = lerp(this.x, this.newX, amt);
            this.y = lerp(this.y, this.newY, amt);
        };
        function lerp(start, end, amt) {
            return (1 - amt) * start + amt * end;
        }
    }
    return Rotator;
}());
exports.default = Rotator;
