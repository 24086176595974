"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *
 *
 * @export
 * @param {HTMLElement} el
 * @param {Event} evt
 * @returns {x: number, y: number}
 */
function getInteractionPoint(el, evt) {
    var rect = el.getBoundingClientRect();
    var position;
    if (evt.type == "touchstart" || evt.type == "touchmove") {
        position = evt.touches[0];
    }
    else {
        position = evt;
    }
    return {
        x: (position.clientX - rect.left),
        y: (position.clientY - rect.top)
    };
}
exports.default = getInteractionPoint;
