"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pageTypes_1 = require("../constants/pageTypes");
/**
 * Returns either "home", "explore" or "default" depending on the current page. (uses the body class)
 *
 * @export
 * @returns {string}
 */
function getCurrentPage() {
    var bodyClasses = document.body.classList;
    if (bodyClasses.contains("home-page")) {
        return pageTypes_1.PAGE_TYPES.home;
    }
    else if (bodyClasses.contains("explore-page")) {
        return pageTypes_1.PAGE_TYPES.explore;
    }
    else {
        return pageTypes_1.PAGE_TYPES.default;
    }
}
exports.default = getCurrentPage;
