"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var removeFromArray_1 = require("../utils/removeFromArray");
var callbackUtils_1 = require("../utils/callbackUtils");
var PjaxService = /** @class */ (function () {
    function PjaxService() {
        var animationOutDoneCallbacks = [];
        var animationOutStartCallbacks = [];
        var clickLinkCallbacks = [];
        var pageViewCallbacks = [];
        var contentReplacedCallbacks = [];
        var swupAnimationSkipped = false;
        var pjaxEnabled = false;
        document.addEventListener("swup:clickLink", handleClickLinkEvent);
        document.addEventListener("swup:animationSkipped", handleAnimationSkippedEvent);
        document.addEventListener("swup:pageView", handlePageViewEvent);
        document.addEventListener("swup:contentReplaced", handleContentReplacedEvent);
        document.addEventListener("swup:animationOutStart", handleAnimationOutStartEvent);
        document.addEventListener("swup:animationOutDone", handleAnimationOutDoneEvent);
        this.skipAnimation = function () {
            return pjaxEnabled === true && swupAnimationSkipped === false; // Only skip animation if swup animation was present;
        };
        /**
         *  Add / Remove Animation Out Done Events
         */
        this.addToAnimationOutDoneEvents = function (callback) {
            addCallback(callback, animationOutDoneCallbacks);
        };
        this.removeFromAnimationOutDoneEvents = function (callback) {
            animationOutDoneCallbacks = removeFromArray_1.default(animationOutDoneCallbacks, callback);
        };
        /**
         *  Add / Remove Animation Out Start Events
         */
        this.addToAnimationOutStartEvents = function (callback) {
            addCallback(callback, animationOutStartCallbacks);
        };
        this.removeFromAnimationOutStartEvents = function (callback) {
            animationOutStartCallbacks = removeFromArray_1.default(animationOutStartCallbacks, callback);
        };
        /**
         *  Add / Remove Click Link Events
         */
        this.addToClickLinkEvents = function (callback) {
            addCallback(callback, clickLinkCallbacks);
        };
        this.removeFromClickLinkEvents = function (callback) {
            clickLinkCallbacks = removeFromArray_1.default(clickLinkCallbacks, callback);
        };
        /**
         *  Add / Remove Page View Events
         */
        this.addToPageViewEvents = function (callback) {
            addCallback(callback, pageViewCallbacks);
        };
        this.removeFromPageViewEvents = function (callback) {
            pageViewCallbacks = removeFromArray_1.default(pageViewCallbacks, callback);
        };
        /**
         *  Add / Remove Content Replaces Events
         */
        this.addToContentReplacedEvents = function (callback) {
            addCallback(callback, contentReplacedCallbacks);
        };
        this.removeFromContentReplacedEvents = function (callback) {
            contentReplacedCallbacks = removeFromArray_1.default(contentReplacedCallbacks, callback);
        };
        /**
         *  Methods for handling different swup events
         */
        function handleClickLinkEvent(event) {
            callbackUtils_1.runCallbacks(clickLinkCallbacks, event);
        }
        function handleAnimationOutStartEvent(event) {
            pjaxEnabled = true;
            callbackUtils_1.runCallbacks(animationOutStartCallbacks, event);
        }
        function handleAnimationOutDoneEvent(event) {
            callbackUtils_1.runCallbacks(animationOutDoneCallbacks, event);
        }
        function handleAnimationSkippedEvent() {
            swupAnimationSkipped = true;
        }
        ;
        function handlePageViewEvent(event) {
            callbackUtils_1.runCallbacks(pageViewCallbacks, event);
        }
        function handleContentReplacedEvent(event) {
            callbackUtils_1.runCallbacks(contentReplacedCallbacks, event);
            if (swupAnimationSkipped === true) {
                swupAnimationSkipped = false;
            }
        }
        ;
        function addCallback(callback, callbackArray) {
            if (callback && callbackArray.indexOf(callback) === -1) {
                return callbackArray.push(callback);
            }
        }
        ;
    }
    return PjaxService;
}());
var pjaxService = new PjaxService();
exports.default = pjaxService;
