"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getFormDataAsJsonObject(form) {
    var data = new FormData(form);
    var result = {};
    data.forEach(function (value, key) {
        result[key] = value;
    });
    return result;
}
exports.getFormDataAsJsonObject = getFormDataAsJsonObject;
function getFormDataAsJsonString(form) {
    var obj = getFormDataAsJsonObject(form);
    return JSON.stringify(obj);
}
exports.getFormDataAsJsonString = getFormDataAsJsonString;
