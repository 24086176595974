"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var removeFromArray_1 = require("../utils/removeFromArray");
var WindowEventService = /** @class */ (function () {
    function WindowEventService() {
        var mouseMoveCallbacks = [];
        var touchMoveCallbacks = [];
        var running = false;
        this.addMouseMoveEvent = function (callback) {
            if (!mouseMoveCallbacks.length) {
                window.addEventListener('mousemove', handleMouseMoveEvent, false);
            }
            mouseMoveCallbacks = _addCallback(mouseMoveCallbacks, callback);
        };
        this.removeMouseMoveEvent = function (callback) {
            mouseMoveCallbacks = removeFromArray_1.default(mouseMoveCallbacks, callback);
            if (!mouseMoveCallbacks.length) {
                window.removeEventListener('mousemove', handleMouseMoveEvent, false);
            }
        };
        this.addTouchMoveEvent = function (callback) {
            if (!mouseMoveCallbacks.length) {
                window.addEventListener('touchmove', handleTouchMoveEvent, false);
            }
            touchMoveCallbacks = _addCallback(touchMoveCallbacks, callback);
        };
        this.removeTouchMoveEvent = function (callback) {
            touchMoveCallbacks = removeFromArray_1.default(touchMoveCallbacks, callback);
            if (!mouseMoveCallbacks.length) {
                window.removeEventListener('touchmove', handleTouchMoveEvent, false);
            }
        };
        // fired on mousemove event
        function handleMouseMoveEvent(e) {
            if (!running) {
                running = true;
                // e.preventDefault();
                _runCallbacks(e, mouseMoveCallbacks);
            }
        }
        // fired on mousemove event
        function handleTouchMoveEvent(e) {
            if (!running) {
                running = true;
                // e.preventDefault();
                _runCallbacks(e, touchMoveCallbacks);
            }
        }
        // run the actual callbacks
        function _runCallbacks(e, callbackArray) {
            mouseMoveCallbacks.forEach(function (callback) {
                callback(e);
            });
            running = false;
        }
        // adds callback to loop
        function _addCallback(callbackArray, callback) {
            if (callback && callbackArray.indexOf(callback) === -1) {
                callbackArray.push(callback);
            }
            return callbackArray;
        }
    }
    return WindowEventService;
}());
var windowEventService = new WindowEventService();
exports.default = windowEventService;
