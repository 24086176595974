"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ELEMENT_NAME = "drag-drop-input";
var DragDropInput = /** @class */ (function (_super) {
    __extends(DragDropInput, _super);
    function DragDropInput() {
        return _super.call(this) || this;
    }
    DragDropInput.prototype.connectedCallback = function () {
        var _this = this;
        this.inputEl = this.querySelector("input[type=\"file\"]");
        if (this.inputEl === null) {
            console.error("drag-drop-input required an child element of input[type=\"file\"]");
        }
        this.labelError = this.querySelector("." + ELEMENT_NAME + "__error");
        this.buttonText = this.querySelector("." + ELEMENT_NAME + "__button-text");
        this.inputEl.addEventListener("change", this.handleFileInputChangeEvent.bind(this));
        ["dragenter", "dragover"].forEach(function (eventName) {
            _this.addEventListener(eventName, highlight, false);
        });
        ["dragleave", "drop"].forEach(function (eventName) {
            _this.addEventListener(eventName, unhighlight, false);
        });
        function highlight(e) {
            this.classList.add(DragDropInput.HIGHLIGHT_CLASS);
        }
        function unhighlight(e) {
            this.classList.remove(DragDropInput.HIGHLIGHT_CLASS);
        }
    };
    DragDropInput.prototype.handleFileInputChangeEvent = function (event) {
        var target = event.target;
        var file = target !== null ? target.files[0] : null;
        if (file) {
            this.buttonText.innerText = file.name;
        }
        else {
            this.buttonText.innerText = "Upload an image";
        }
        if (this.inputEl.hasAttribute("required")) {
            this.validate();
        }
    };
    DragDropInput.prototype.validate = function () {
        var isValid = this.inputEl.checkValidity();
        if (isValid) {
            this.classList.remove(DragDropInput.INVALID_CLASS);
            if (this.labelError !== null) {
                this.labelError.setAttribute("aria-hidden", "true");
            }
        }
        else {
            this.classList.add(DragDropInput.INVALID_CLASS);
            if (this.labelError !== null) {
                this.labelError.removeAttribute("aria-hidden");
            }
        }
        return isValid;
    };
    DragDropInput.HIGHLIGHT_CLASS = ELEMENT_NAME + "--highlight";
    DragDropInput.INVALID_CLASS = ELEMENT_NAME + "--invalid";
    return DragDropInput;
}(HTMLElement));
exports.DragDropInput = DragDropInput;
customElements.define(ELEMENT_NAME, DragDropInput);
