"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Runs handler when the DOM is ready.
 *
 * @param {Function} handler
 */
function ready(handler) {
    if (document.readyState !== "loading") {
        handler();
    }
    else {
        document.addEventListener("DOMContentLoaded", handler);
    }
}
exports.default = ready;
