"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var AutoheightTextarea = /** @class */ (function (_super) {
    __extends(AutoheightTextarea, _super);
    function AutoheightTextarea() {
        return _super.call(this) || this;
    }
    AutoheightTextarea.prototype.connectedCallback = function () {
        var _this = this;
        this.handleInputEvent = this.handleInputEvent.bind(this);
        this.textarea = this.querySelector("textarea");
        if (this.textarea === null) {
            console.error("No <textarea> element found in <autoheight-textarea>");
            return;
        }
        this.minHeight = this.textarea.clientHeight;
        this.maxLength = this.textarea.hasAttribute("maxlength") ? parseInt(this.textarea.getAttribute("maxlength")) : 0;
        this.charCountEl = this.maxLength > 0 ? this.querySelector("[char-count]") : null;
        this.textarea.addEventListener("input", this.handleInputEvent);
        this.iObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    _this.handleInputEvent();
                }
            });
        }, { threshold: 0.1 });
        this.iObserver.observe(this);
    };
    AutoheightTextarea.prototype.disconnectedCallback = function () {
        this.textarea.removeEventListener("input", this.handleInputEvent);
        this.iObserver.disconnect();
    };
    AutoheightTextarea.prototype.handleInputEvent = function () {
        if (this.textarea.scrollHeight > this.minHeight) {
            this.textarea.style.height = "";
            this.textarea.style.height = this.textarea.scrollHeight + 1 + "px";
        }
        if (this.maxLength > 0 && this.charCountEl !== null) {
            this.charCountEl.innerText = "" + (this.maxLength - this.textarea.value.length);
        }
    };
    return AutoheightTextarea;
}(HTMLElement));
exports.AutoheightTextarea = AutoheightTextarea;
customElements.define("autoheight-textarea", AutoheightTextarea);
