"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var LoadScriptService = /** @class */ (function () {
    function LoadScriptService() {
        this.loadedScripts = [];
        this.isLoading = false;
    }
    /**
     * Load a script and returns a promise once load event has fired
     *
     * @param {string} url
     * @returns
     * @memberof LoadScriptService
     */
    LoadScriptService.prototype.loadScript = function (url, waitForLoadEvent) {
        var _this = this;
        if (waitForLoadEvent === void 0) { waitForLoadEvent = true; }
        return new Promise(function (resolve, reject) {
            var hasScript = false;
            _this.loadedScripts.forEach(function (script) {
                if (script.getAttribute("src") === url) {
                    hasScript = true;
                }
            });
            if (hasScript === false && _this.isLoading === false) {
                _this.isLoading = true;
                var newScript_1 = document.createElement("script");
                newScript_1.type = "text/javascript";
                newScript_1.async = true;
                newScript_1.defer = true;
                newScript_1.src = url;
                document.body.appendChild(newScript_1);
                if (waitForLoadEvent === true) {
                    newScript_1.addEventListener("load", function () {
                        _this.loadedScripts.push(newScript_1);
                        _this.isLoading = false;
                        resolve();
                    });
                }
                else {
                    _this.loadedScripts.push(newScript_1);
                    _this.isLoading = false;
                    resolve();
                }
                newScript_1.addEventListener("error", function () {
                    _this.isLoading = false;
                    reject(new Error(url + " failed to load."));
                });
            }
            else {
                // Wait a bit a try again
                requestAnimationFrame(function () {
                    return _this.loadScript(url).finally(function () {
                        resolve();
                    });
                });
            }
        });
    };
    /**
     * Loads multiple scripts and returns a promise once complete
     *
     * @param {string[]} urls
     * @returns
     * @memberof LoadScriptService
     */
    LoadScriptService.prototype.loadScripts = function (urls) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            urls.forEach(function (url, index) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadScript(url)
                                .catch(function () {
                                reject();
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
            resolve();
        });
    };
    /**
     * Removes script a tag according to its url
     *
     * @param {string} url
     * @returns
     * @memberof LoadScriptService
     */
    LoadScriptService.prototype.removeScript = function (url) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.loadedScripts.forEach(function (script, index) {
                if (script.getAttribute("src") === url) {
                    script.remove();
                    _this.loadedScripts = _this.loadedScripts.splice(index, 1);
                    resolve();
                }
            });
            reject(new Error("Unable to remove script: " + url));
        });
    };
    /**
     * Removes script tags according to their urls in order of appearance in array
     *
     * @param {string[]} urls
     * @returns
     * @memberof LoadScriptService
     */
    LoadScriptService.prototype.removeScripts = function (urls) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            urls.forEach(function (url) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.removeScript(url)
                                .catch(function () {
                                reject();
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
            resolve();
        });
    };
    return LoadScriptService;
}());
var loadScriptService = new LoadScriptService();
exports.default = loadScriptService;
