"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Swup = require("swup/dist/swup.min.js");
var ready_1 = require("../utils/ready");
var swupOptions_1 = require("../constants/swupOptions");
ready_1.default(function () {
    if (window.nativeCustomElementSupport) {
        window.swup = new Swup(swupOptions_1.SWUP_OPTIONS);
        var scrollValues_1 = {};
        document.addEventListener('swup:clickLink', function (event) {
            scrollValues_1[window.location.href] = window.scrollY;
        });
        document.addEventListener('swup:popState', function (event) {
            if (scrollValues_1[window.location.href] !== undefined) {
                window.scrollTo(0, scrollValues_1[window.location.href]);
            }
        });
        document.addEventListener("swup:contentReplaced", function () {
            window.scrollTo(0, 0);
        });
    }
});
