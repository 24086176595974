"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var resizeService_1 = require("../services/resizeService");
var scrollService_1 = require("../services/scrollService");
var pjaxService_1 = require("../services/pjaxService");
var globalCssClasses_1 = require("../constants/globalCssClasses");
var ELEMENT_NAME = "site-header";
var SITE_NAV_OPEN_CLASS = globalCssClasses_1.GLOBAL_CSS_CLASSES.siteNavOpen;
var FIXED_WINDOW_CLASS = globalCssClasses_1.GLOBAL_CSS_CLASSES.fixedWindow;
var SiteHeader = /** @class */ (function (_super) {
    __extends(SiteHeader, _super);
    function SiteHeader() {
        var _this = _super.call(this) || this;
        _this.openHeight = window.innerHeight;
        _this.mobTopHeight = 0;
        _this.navIsOpen = false;
        _this.navAnimating = false;
        return _this;
    }
    // Fires when the custom element is appended into the document-connected element
    SiteHeader.prototype.connectedCallback = function () {
        this.init();
    };
    SiteHeader.prototype.open = function () {
        this.navIsOpen = true;
        scrollService_1.default.saveScrollPosition();
        document.documentElement.classList.add(SITE_NAV_OPEN_CLASS);
    };
    SiteHeader.prototype.close = function () {
        document.documentElement.classList.remove(FIXED_WINDOW_CLASS);
        scrollService_1.default.scrollToLastPosition();
        document.documentElement.classList.remove(SITE_NAV_OPEN_CLASS);
        this.navIsOpen = false;
    };
    SiteHeader.prototype.closeForModal = function () {
        document.documentElement.classList.remove(SITE_NAV_OPEN_CLASS);
        this.navIsOpen = false;
    };
    SiteHeader.prototype.init = function () {
        // Get nav toggle and each section of the mobile navigation
        this.navToggleEl = this.querySelector(".nav-toggle");
        this.siteHeaderEl = this.querySelector("." + ELEMENT_NAME);
        this.mobTopEl = this.querySelector("." + ELEMENT_NAME + "__mob-top");
        this.mobNavWrapperEl = this.querySelector("." + ELEMENT_NAME + "__mob-bottom");
        this.mobNavContainerEl = this.querySelector("." + ELEMENT_NAME + "__nav-container");
        // Get height of top part of header and height of window
        this.openHeight = window.innerHeight;
        this.mobTopHeight = this.mobTopEl.clientHeight;
        // Bind event for handling transition end
        this.handleHeaderTransitionEndEvent = this.handleHeaderTransitionEndEvent.bind(this);
        // Set initial mobile navigation element height
        this.setMobNavWrapperHeight();
        this.setBackgroundColor();
        // Bind event listener for navigation toggle
        this.navToggleEl.addEventListener("click", this.handleToggleClickEvent.bind(this));
        // Handle pjax animation done event (e.g. close nav)
        pjaxService_1.default.addToClickLinkEvents(this.handlePjaxClickLinkEvent.bind(this));
        // Reinit mobNavContainerEl after content replaced
        pjaxService_1.default.addToContentReplacedEvents(this.handlePjaxContentReplacedEvent.bind(this));
        // Add resize event to handle changes in navigation height
        resizeService_1.default.add(this.handleResizeEvent.bind(this));
        scrollService_1.default.add(this.setBackgroundColor.bind(this));
    };
    SiteHeader.prototype.handleResizeEvent = function () {
        this.openHeight = window.innerHeight;
        this.mobTopHeight = this.mobTopEl.clientHeight;
        this.setMobNavWrapperHeight();
        this.setBackgroundColor();
        if (resizeService_1.default.isDesktop() === true) {
            this.resetMobileHeader();
        }
    };
    SiteHeader.prototype.handleToggleClickEvent = function () {
        if (this.navAnimating === false) {
            this.navAnimating = true;
            this.mobNavContainerEl.addEventListener("transitionend", this.handleHeaderTransitionEndEvent);
            if (this.navIsOpen === true) {
                this.close();
            }
            else {
                this.open();
            }
        }
    };
    SiteHeader.prototype.handleHeaderTransitionEndEvent = function (event) {
        if (event.propertyName === "opacity") {
            if (this.navIsOpen === true) {
                document.documentElement.classList.add(FIXED_WINDOW_CLASS);
            }
            this.navAnimating = false;
            this.mobNavContainerEl.removeEventListener("transitionend", this.handleHeaderTransitionEndEvent);
        }
    };
    SiteHeader.prototype.handlePjaxClickLinkEvent = function () {
        if (this.navIsOpen === true) {
            document.documentElement.classList.remove(FIXED_WINDOW_CLASS);
            this.closeForModal();
        }
    };
    SiteHeader.prototype.handlePjaxContentReplacedEvent = function () {
        this.mobNavContainerEl = this.querySelector("." + ELEMENT_NAME + "__nav-container");
        this.setBackgroundColor();
    };
    SiteHeader.prototype.resetMobileHeader = function () {
        document.documentElement.classList.remove(FIXED_WINDOW_CLASS);
        document.documentElement.classList.remove(SITE_NAV_OPEN_CLASS);
        this.navIsOpen = false;
        this.navAnimating = false;
        Object.assign(this.mobNavWrapperEl.style, {
            height: "",
            top: ""
        });
    };
    SiteHeader.prototype.setMobNavWrapperHeight = function () {
        if (resizeService_1.default.isDesktop() === true) {
            Object.assign(this.mobNavWrapperEl.style, {
                height: "",
                top: ""
            });
        }
        else {
            Object.assign(this.mobNavWrapperEl.style, {
                height: this.openHeight - this.mobTopHeight + "px",
                top: this.mobTopHeight + "px"
            });
        }
    };
    SiteHeader.prototype.setBackgroundColor = function () {
        if (resizeService_1.default.isDesktop() === true && scrollService_1.default.getScrollPosition() <= 5) {
            this.siteHeaderEl.style.background = "transparent";
        }
        else {
            this.siteHeaderEl.style.background = "";
        }
    };
    return SiteHeader;
}(HTMLElement));
exports.SiteHeader = SiteHeader;
customElements.define(ELEMENT_NAME, SiteHeader);
