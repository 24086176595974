"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var genericForm_1 = require("./genericForm");
var querySelectorAll_1 = require("../utils/querySelectorAll");
var EditTributeForm = /** @class */ (function (_super) {
    __extends(EditTributeForm, _super);
    function EditTributeForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EditTributeForm.prototype.connectedCallback = function () {
        _super.prototype.connectedCallback.call(this);
        this._firstNameEls = querySelectorAll_1.default(".js-first-name");
        this._imageEl = this.querySelector(".js-image");
        this._imageExtensionEl = this.querySelector(".js-image-extension");
    };
    EditTributeForm.prototype.onSuccess = function (json) {
        _super.prototype.onSuccess.call(this, json, true);
        var oldUrl = window.location.pathname;
        var newUrl = json.EditUrl;
        if (oldUrl !== newUrl) {
            window.swup.cache.remove(oldUrl);
            window.history.replaceState({}, "", newUrl);
        }
        if (this._firstNameEls) {
            this._firstNameEls.forEach(function (firstNameEl) {
                firstNameEl.innerText = json.FirstName;
            });
        }
        if (this._imageEl) {
            this._imageEl.src = json.ImageUrl;
        }
        if (this._imageExtensionEl) {
            this._imageExtensionEl.value = json.ImageExtension;
        }
    };
    return EditTributeForm;
}(genericForm_1.GenericForm));
exports.EditTributeForm = EditTributeForm;
customElements.define("edit-tribute-form", EditTributeForm);
