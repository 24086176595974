"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var PageBorder = /** @class */ (function (_super) {
    __extends(PageBorder, _super);
    function PageBorder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._render = function () {
            _this._requestId = requestAnimationFrame(_this._render);
            var height = window.innerHeight + "px";
            _this.style.height = height;
            _this._left.style.height = height;
            _this._right.style.height = height;
        };
        return _this;
    }
    PageBorder.prototype.connectedCallback = function () {
        this._left = this.querySelector("span:nth-child(3)");
        this._right = this.querySelector("span:nth-child(4)");
        this._requestId = requestAnimationFrame(this._render);
    };
    PageBorder.prototype.disconnectedCallback = function () {
        cancelAnimationFrame(this._requestId);
    };
    return PageBorder;
}(HTMLElement));
exports.PageBorder = PageBorder;
customElements.define("page-border", PageBorder);
