"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
window.nativeCustomElementSupport = "customElements" in window;
require("@webcomponents/custom-elements/src/native-shim.js");
require("@webcomponents/custom-elements/custom-elements.min.js");
require("./polyfills/whatInput");
require("./polyfills/objectFitImages");
// This needs to load before swup and will handle all of the petal loading & interactions.
var mcPetals3d_1 = require("./classes/mcPetals3d");
var petals = new mcPetals3d_1.default();
require("./pjax/swup");
require("./customElements/smoothScroll");
require("./customElements/addTributeForm");
require("./customElements/formField");
require("./customElements/formPage");
require("./customElements/siteHeader");
require("./customElements/shareLinks");
require("./customElements/shareLink");
require("./customElements/mcButton");
require("./customElements/mcModal");
require("./customElements/mcNotification");
// Possibly load this scripts seperately just for tribute page
require("./customElements/copyLink");
require("./customElements/textRevealer");
require("./customElements/memoryImage");
require("./customElements/imageSwiper");
require("./customElements/addMemory");
require("./customElements/autoheightTextarea");
require("./customElements/dragDropInput");
require("./customElements/editMemoryForm");
require("./customElements/editTributeForm");
require("./customElements/genericForm");
require("./customElements/accordionEl");
require("./customElements/pageBorder");
