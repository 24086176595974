"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var removeFromArray_1 = require("../utils/removeFromArray");
var callbackUtils_1 = require("../utils/callbackUtils");
var globalCssClasses_1 = require("../constants/globalCssClasses");
var ScrollService = /** @class */ (function () {
    function ScrollService() {
        var queue = [];
        var rafStarted = false;
        var lastScrollPosition;
        var currentScrollPosition;
        this.add = function (callBack) {
            queue.push(callBack);
            if (window.requestAnimationFrame) {
                if (!rafStarted) {
                    updateScrollPosition();
                }
            }
        };
        this.remove = function (callBack) {
            queue.push(callBack);
            removeFromArray_1.default(queue, callBack);
        };
        this.getScrollPosition = function () {
            setCurrentScrollPosition();
            return currentScrollPosition;
        };
        this.saveScrollPosition = function () {
            lastScrollPosition = this.getScrollPosition();
        };
        this.scrollTo = function (position) {
            window.scrollTo({
                top: position
            });
            setCurrentScrollPosition();
        };
        this.scrollToLastPosition = function () {
            this.scrollTo(lastScrollPosition);
        };
        this.getLastScrollPosition = function () {
            return lastScrollPosition;
        };
        function setCurrentScrollPosition() {
            if (window.pageYOffset) {
                currentScrollPosition = window.pageYOffset;
            }
            else if (document.documentElement && document.documentElement.scrollTop) {
                currentScrollPosition = document.documentElement.scrollTop;
            }
            else if (document.body.scrollTop) {
                currentScrollPosition = document.body.scrollTop;
            }
            else {
                currentScrollPosition = 0;
            }
        }
        function updateScrollPosition() {
            rafStarted = true;
            setCurrentScrollPosition();
            if (lastScrollPosition !== currentScrollPosition) {
                if (document.documentElement.classList.contains(globalCssClasses_1.GLOBAL_CSS_CLASSES.fixedWindow) === false) {
                    lastScrollPosition = currentScrollPosition;
                    callbackUtils_1.runCallbacks(queue, null);
                }
            }
            window.requestAnimationFrame(function () { return updateScrollPosition(); });
        }
    }
    return ScrollService;
}());
var scrollService = new ScrollService();
exports.default = scrollService;
