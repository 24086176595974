"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var genericForm_1 = require("./genericForm");
var ancestorOrSelf_1 = require("../utils/ancestorOrSelf");
var EditMemoryForm = /** @class */ (function (_super) {
    __extends(EditMemoryForm, _super);
    function EditMemoryForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClickDelete = function (event) {
            event.preventDefault();
            var confirmMessage = _this._deleteButton.getAttribute("data-confirm-message");
            var action = _this._deleteButton.getAttribute("data-action");
            var method = _this._deleteButton.getAttribute("data-method");
            if (!confirm(confirmMessage)) {
                return;
            }
            _this.submit(action, method, function () {
                var item = ancestorOrSelf_1.default(_this, ".js-item");
                if (item) {
                    item.parentElement.removeChild(item);
                }
                _this.setIsWorking(false);
            });
        };
        return _this;
    }
    EditMemoryForm.prototype.connectedCallback = function () {
        _super.prototype.connectedCallback.call(this);
        this._deleteButton = this.querySelector(".js-delete");
        if (this._deleteButton) {
            this._deleteButton.addEventListener("click", this.onClickDelete);
        }
    };
    EditMemoryForm.prototype.onSuccess = function () {
        this.setIsWorking(false);
    };
    return EditMemoryForm;
}(genericForm_1.GenericForm));
exports.EditMemoryForm = EditMemoryForm;
customElements.define("edit-memory-form", EditMemoryForm);
