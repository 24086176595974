"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var gsap_1 = require("gsap");
var globalCssClasses_1 = require("../constants/globalCssClasses");
var scrollService_1 = require("../services/scrollService");
var querySelectorAll_1 = require("../utils/querySelectorAll");
var ELEMENT_NAME = "mc-modal";
var ACTIVE_CLASS = ELEMENT_NAME + "__overlay--active";
var SITE_NAV_OPEN_CLASS = globalCssClasses_1.GLOBAL_CSS_CLASSES.siteNavOpen;
var FIXED_WINDOW_CLASS = globalCssClasses_1.GLOBAL_CSS_CLASSES.fixedWindow;
var McModal = /** @class */ (function (_super) {
    __extends(McModal, _super);
    function McModal() {
        var _this = _super.call(this) || this;
        _this.ignoreUtilFocusChanges = false;
        _this.modalActive = false;
        _this.removeOnClose = false;
        return _this;
    }
    McModal.prototype.connectedCallback = function () {
        this.validateElements = this.validateElements.bind(this);
        this.init();
    };
    McModal.prototype.disconnectedCallback = function () {
        this.deactiveKeyboardTrap();
        this.overlayEl.remove();
        if (document.documentElement.classList.contains(SITE_NAV_OPEN_CLASS) === false) {
            document.documentElement.classList.remove(FIXED_WINDOW_CLASS);
        }
    };
    McModal.prototype.init = function () {
        var _this = this;
        this.autoOpen = this.hasAttribute("auto-open");
        this.removeOnClose = this.hasAttribute("remove-on-close");
        this.openEl = this.querySelector("." + ELEMENT_NAME + "__open");
        this.modalTemplate = this.querySelector("template");
        if (this.modalTemplate === null) {
            console.error("No template found in mc-modal");
            return;
        }
        this.overlayEl = document.createElement("div");
        this.overlayEl.innerHTML = this.modalTemplate.innerHTML;
        var overlayElAttributes = Array.prototype.slice.call(this.modalTemplate.attributes);
        overlayElAttributes.forEach(function (item) {
            _this.overlayEl.setAttribute(item.name, item.value);
        });
        this.closeEl = this.overlayEl.querySelector("." + ELEMENT_NAME + "__close");
        this.siteHeader = document.querySelector("site-header");
        if (this.validateElements() === true) {
            this.trapFocus = this.trapFocus.bind(this);
            this.handleEscape = this.handleEscape.bind(this);
            document.body.appendChild(this.overlayEl);
            if (this.openEl !== null) {
                this.openEl.addEventListener("click", this.openOverlay.bind(this));
            }
            if (this.autoOpen === true) {
                this.openOverlay();
            }
            this.closeEl.addEventListener("click", this.closeOverlay.bind(this));
        }
    };
    McModal.prototype.openOverlay = function () {
        var _this = this;
        if (this.modalActive === false) {
            this.modalActive = true;
            scrollService_1.default.saveScrollPosition();
            document.addEventListener("keyup", this.handleEscape);
            this.overlayEl.classList.add(ACTIVE_CLASS);
            gsap_1.TweenLite.fromTo(this.overlayEl, .7, {
                opacity: 0
            }, {
                opacity: 1,
                ease: gsap_1.Power2.easeInOut,
                onComplete: function () {
                    document.documentElement.classList.add(FIXED_WINDOW_CLASS);
                    _this.activeKeyboardTrap();
                    if (_this.siteHeader !== null) {
                        _this.siteHeader.closeForModal();
                    }
                }
            });
        }
    };
    McModal.prototype.closeOverlay = function () {
        var _this = this;
        if (this.modalActive === true) {
            // Don't remove fixed window class is mobile nav is open
            if (document.documentElement.classList.contains(SITE_NAV_OPEN_CLASS) === false) {
                document.documentElement.classList.remove(FIXED_WINDOW_CLASS);
            }
            scrollService_1.default.scrollToLastPosition();
            var timeline = new gsap_1.TimelineLite({
                onComplete: function () {
                    _this.overlayEl.classList.remove(ACTIVE_CLASS);
                    _this.deactiveKeyboardTrap();
                    if (_this.openEl !== null) {
                        _this.openEl.focus();
                    }
                    _this.modalActive = false;
                    document.removeEventListener("keyup", _this.handleEscape);
                    // If modal contains add-memory component, reset it once modal is closed
                    var addMemoryEl = querySelectorAll_1.default("add-memory", _this.overlayEl);
                    addMemoryEl.forEach(function (el) {
                        el.reset();
                    });
                    if (_this.removeOnClose === true) {
                        var url = window.location.pathname;
                        var lastPart = _this.getLastPartOfUrl(url);
                        if (lastPart == "new") {
                            var newUrl = url.substr(0, url.lastIndexOf("new"));
                            window.swup.cache.remove(url);
                            window.history.replaceState({}, "", newUrl);
                        }
                        _this.overlayEl.remove();
                        _this.remove();
                    }
                }
            });
            timeline.to(this.overlayEl, .4, { opacity: 0, }, 0);
        }
    };
    McModal.prototype.validateElements = function () {
        if ((this.autoOpen === false && this.openEl === null) || this.closeEl === null) {
            console.warn("No button with class " + (this.openEl === null ? "\"mc-modal__open\"" : "\"mc-modal__close\"") + " found in mc-model element");
            return false;
        }
        if (this.overlayEl === null) {
            console.warn("No element with class \"mc-modal__overlay\" found in mc-model element");
            return false;
        }
        return true;
    };
    // Accessibility related methods - https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html
    McModal.prototype.handleEscape = function (event) {
        var key = event.which || event.keyCode;
        if (key === 27 && this.modalActive === true) {
            event.preventDefault();
            this.closeOverlay();
        }
    };
    McModal.prototype.activeKeyboardTrap = function () {
        // Bracket the dialog node with two invisible, focusable nodes.
        // While this dialog is open, we use these to make sure that focus never
        // leaves the document even if dialogNode is the first or last node.
        var preDiv = document.createElement('div');
        this.preNode = this.overlayEl.parentNode.insertBefore(preDiv, this.overlayEl);
        this.preNode.tabIndex = 0;
        var postDiv = document.createElement('div');
        this.postNode = this.overlayEl.parentNode.insertBefore(postDiv, this.overlayEl.nextSibling);
        this.postNode.tabIndex = 0;
        this.focusFirstDescendant(this.overlayEl);
        document.addEventListener("focus", this.trapFocus, true);
    };
    McModal.prototype.deactiveKeyboardTrap = function () {
        document.removeEventListener("focus", this.trapFocus, true);
        if (this.preNode) {
            this.preNode.remove();
        }
        if (this.postNode) {
            this.postNode.remove();
        }
    };
    /**
       * @desc Set focus on descendant nodes until the first focusable element is
       *       found.
       * @param element
       *          DOM node for which to find the first focusable descendant.
       * @returns
       *  true if a focusable element is found and focus is set.
       */
    McModal.prototype.focusFirstDescendant = function (element) {
        for (var i = 0; i < element.children.length; i++) {
            var child = element.children[i];
            if (this.attemptFocus(child) ||
                this.focusFirstDescendant(child)) {
                return true;
            }
        }
        return false;
    };
    ;
    /**
     * @desc Find the last descendant node that is focusable.
     * @param element
     *          DOM node for which to find the last focusable descendant.
     * @returns
     *  true if a focusable element is found and focus is set.
     */
    McModal.prototype.focusLastDescendant = function (element) {
        for (var i = element.children.length - 1; i >= 0; i--) {
            var child = element.children[i];
            if (this.attemptFocus(child) ||
                this.focusLastDescendant(child)) {
                return true;
            }
        }
        return false;
    };
    /**
     * @desc Set Attempt to set focus on the current node.
     * @param element
     *          The node to attempt to focus on.
     * @returns
     *  true if element is focused.
     */
    McModal.prototype.attemptFocus = function (element) {
        this.ignoreUtilFocusChanges = true;
        try {
            element.focus();
        }
        catch (e) {
        }
        this.ignoreUtilFocusChanges = false;
        return (document.activeElement === element);
    };
    ;
    /**
     * @desc Traps keyboard focus to withing active overlay
     * @param {FocusEvent} event
     * @returns
     */
    McModal.prototype.trapFocus = function (event) {
        if (this.ignoreUtilFocusChanges) {
            return;
        }
        if (this.overlayEl.contains(event.target)) {
            this.lastFocus = event.target;
        }
        else {
            this.focusFirstDescendant(this.overlayEl);
            if (this.lastFocus === document.activeElement) {
                this.focusLastDescendant(this.overlayEl);
            }
            this.lastFocus = document.activeElement;
        }
    };
    McModal.prototype.getLastPartOfUrl = function (url) {
        var parts = url.split("/");
        return (url.lastIndexOf('/') !== url.length - 1
            ? parts[parts.length - 1]
            : parts[parts.length - 2]);
    };
    return McModal;
}(HTMLElement));
customElements.define(ELEMENT_NAME, McModal);
