"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var querySelectorAll_1 = require("../utils/querySelectorAll");
var FormPage = /** @class */ (function (_super) {
    __extends(FormPage, _super);
    function FormPage() {
        var _this = _super.call(this) || this;
        _this.preNode = null;
        _this.handleUnFocusEvent = _this.handleUnFocusEvent.bind(_this);
        return _this;
    }
    FormPage.prototype.connectedCallback = function () {
        this.fields = querySelectorAll_1.default("form-field", this);
        this.fieldset = this.querySelector("fieldset");
    };
    FormPage.prototype.validate = function () {
        var result = true;
        for (var _i = 0, _a = this.fields; _i < _a.length; _i++) {
            var field = _a[_i];
            if (!field.validate()) {
                if (result === true) {
                    field.focus();
                }
                result = false;
            }
        }
        return result;
    };
    FormPage.prototype.focus = function () {
        if (this.preNode === null) {
            var preDiv = document.createElement('div');
            this.preNode = this.parentNode.insertBefore(preDiv, this);
            this.preNode.tabIndex = 0;
            this.preNode.addEventListener("blur", this.handleUnFocusEvent);
            this.preNode.focus();
        }
    };
    FormPage.prototype.handleUnFocusEvent = function () {
        if (this.preNode !== null) {
            this.preNode.removeEventListener("blur", this.handleUnFocusEvent);
            this.preNode.remove();
            this.preNode = null;
        }
    };
    return FormPage;
}(HTMLElement));
exports.FormPage = FormPage;
customElements.define("form-page", FormPage);
