"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Removes item from array if it exists
 *
 * @export
 * @param {any[]} array
 * @param {*} item
 * @returns {any[]}
 */
function removeFromArray(array, item) {
    // copy array before modifying to ensure it doesn't change
    var origArray = array.slice(0);
    var index = origArray.indexOf(item);
    if (index > -1) {
        origArray.splice(index, 1);
    }
    return origArray;
}
exports.default = removeFromArray;
