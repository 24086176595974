"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReCaptchaService = /** @class */ (function () {
    function ReCaptchaService() {
        this._isLoading = false;
        this._isLoaded = false;
        this._loadingPromise = null;
        this._siteKey = null;
    }
    ReCaptchaService.prototype.getSiteKey = function () {
        if (!this._siteKey) {
            var attr = "data-recaptcha-site-key";
            var el = document.querySelector("[" + attr + "]");
            this._siteKey = el && el.getAttribute(attr);
        }
        return this._siteKey;
    };
    ReCaptchaService.prototype.ready = function () {
        var _this = this;
        if (this._isLoading || this._isLoaded) {
            return this._loadingPromise;
        }
        this._isLoading = true;
        this._loadingPromise = new Promise(function (resolve, reject) {
            window[ReCaptchaService.CALLBACK_NAME] = function () {
                _this._isLoading = false;
                _this._isLoaded = true;
                resolve(grecaptcha);
            };
            var script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=" + _this.getSiteKey() + "&onload=" + ReCaptchaService.CALLBACK_NAME;
            document.body.appendChild(script);
            script.onerror = function () {
                _this._isLoading = false;
                reject();
            };
        });
        return this._loadingPromise;
    };
    ReCaptchaService.CALLBACK_NAME = "reCaptchaServiceCallback";
    return ReCaptchaService;
}());
var reCaptchaService = new ReCaptchaService();
exports.default = reCaptchaService;
