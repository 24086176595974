"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AccordionSection = /** @class */ (function () {
    function AccordionSection(section, expanded) {
        var _this = this;
        if (expanded === void 0) { expanded = false; }
        this.section = section;
        this.heading = section.querySelector(".accordion__heading");
        this.content = section.querySelector(".accordion__content");
        this.smoothScrollEl = document.querySelector("smooth-scroll");
        var buttonEl = section.querySelector(".accordion__button");
        if (buttonEl !== null && this.heading !== null && this.content !== null) {
            this.button = document.createElement("button");
            this.button.innerHTML = buttonEl.innerHTML;
            var buttonElAttributes = Array.prototype.slice.call(buttonEl.attributes);
            buttonElAttributes.forEach(function (item) {
                _this.button.setAttribute(item.name, item.value);
            });
            this.button.type = "button";
            this.button.classList.add("button-reset");
            if (this.button.hasAttribute("id")) {
                this.content.setAttribute("aria-labelledby", this.button.getAttribute("id"));
            }
            if (this.content.hasAttribute("id")) {
                this.button.setAttribute("aria-controls", this.content.getAttribute("id"));
            }
            this.heading.appendChild(this.createIcon());
            this.button.addEventListener("click", this.toggle.bind(this));
            this.heading.prepend(this.button);
            buttonEl.remove();
            if (expanded === true) {
                this.button.setAttribute("aria-expanded", "true");
                this.open();
            }
            else {
                this.button.setAttribute("aria-expanded", "false");
            }
        }
    }
    AccordionSection.prototype.toggle = function () {
        if (this.section.classList.contains(AccordionSection.SECTION_EXPANDED)) {
            this.close();
        }
        else {
            this.open();
        }
        if (this.smoothScrollEl !== null) {
            this.smoothScrollEl.resetScrollArea();
        }
    };
    AccordionSection.prototype.open = function () {
        this.section.classList.add(AccordionSection.SECTION_EXPANDED);
        this.button.setAttribute("aria-expanded", "true");
    };
    AccordionSection.prototype.close = function () {
        this.section.classList.remove(AccordionSection.SECTION_EXPANDED);
        this.button.setAttribute("aria-expanded", "false");
    };
    AccordionSection.prototype.createIcon = function () {
        var iconEl = document.createElement("span");
        iconEl.setAttribute("aria-hidden", "true");
        iconEl.classList.add("accordion__icon");
        return iconEl;
    };
    AccordionSection.SECTION_EXPANDED = "accordion__section--expanded";
    return AccordionSection;
}());
exports.default = AccordionSection;
