"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var gsap_1 = require("gsap");
var resizeService_1 = require("../services/resizeService");
var scrollService_1 = require("../services/scrollService");
var pjaxService_1 = require("../services/pjaxService");
var getCurrentPage_1 = require("../utils/getCurrentPage");
var pageTypes_1 = require("../constants/pageTypes");
var mediaQueries_1 = require("../constants/mediaQueries");
// https://tympanus.net/codrops/2019/07/10/how-to-add-smooth-scrolling-with-inner-image-animations-to-a-web-page/
var SmoothScroll = /** @class */ (function (_super) {
    __extends(SmoothScroll, _super);
    function SmoothScroll() {
        var _this = _super.call(this) || this;
        _this.currentPage = "";
        _this.active = false;
        _this.isTouch = false;
        _this.getPageYScroll = _this.getPageYScroll.bind(_this);
        _this.render = _this.render.bind(_this);
        _this.resetScrollArea = _this.resetScrollArea.bind(_this);
        _this.handleResizeEvent = _this.handleResizeEvent.bind(_this);
        _this.handleContentReplacedEvent = _this.handleContentReplacedEvent.bind(_this);
        _this.handleFocusEvent = _this.handleFocusEvent.bind(_this);
        _this.disable = _this.disable.bind(_this);
        return _this;
    }
    SmoothScroll.prototype.connectedCallback = function () {
        if (window.matchMedia(mediaQueries_1.MEDIA_QUERIES.preferesReducedMotion).matches === false) {
            this.handleContentReplacedEvent();
            document.addEventListener("focus", this.handleFocusEvent, true);
            pjaxService_1.default.addToContentReplacedEvents(this.handleContentReplacedEvent);
        }
    };
    SmoothScroll.prototype.init = function () {
        var _this = this;
        this.isTouch = document.documentElement.getAttribute("data-whatinput") === "touch";
        if (this.isTouch === false) {
            if (this.active === false) {
                this.body = document.body;
                scrollService_1.default.scrollTo(0);
                this.calcWinsize();
                this.getPageYScroll();
                scrollService_1.default.add(this.getPageYScroll);
                this.DOM = {
                    main: this,
                    siteHeader: document.querySelector(".site-header"),
                    pageBorder: document.querySelector(".page-border span:first-child"),
                    scrollable: this.querySelector("[data-scroll]")
                };
                this.renderedStyles = {
                    translationY: {
                        previous: 0,
                        current: 0,
                        ease: 0.1,
                        setValue: function () { return _this.docScroll; }
                    }
                };
                this.update();
                this.setSize();
                this.setMainStyle();
                resizeService_1.default.add(this.handleResizeEvent);
                gsap_1.TweenLite.ticker.addEventListener("tick", this.render);
                // If device is also capable of touch events, disable smooth scroll if touch move event fires
                document.addEventListener("touchstart", this.disable);
                document.removeEventListener("mousemove", this.handleContentReplacedEvent);
                this.active = true;
            }
            else {
                this.calcWinsize();
                // this.getPageYScroll(); Fixes MCD-156.
                this.update();
                this.setSize();
                this.setMainStyle();
            }
        }
    };
    SmoothScroll.prototype.disable = function () {
        if (this.active === true) {
            this.active = false;
            scrollService_1.default.remove(this.getPageYScroll);
            resizeService_1.default.remove(this.handleResizeEvent);
            gsap_1.TweenLite.ticker.removeEventListener("tick", this.render);
            // If smooth scroll was disabled via touchmove event, make sure we can reenable it if mouse move event is found (indicating non touch based scroll)
            document.addEventListener("mousemove", this.handleContentReplacedEvent);
            document.removeEventListener("touchstart", this.disable);
            this.body.style.height = this.DOM.scrollable.style.transform = this.DOM.main.style.cssText = this.DOM.scrollable.style.willChange = "";
        }
    };
    SmoothScroll.prototype.resetScrollArea = function () {
        if (this.active === true) {
            this.calcWinsize();
            this.setSize();
            this.update();
        }
    };
    SmoothScroll.prototype.handleContentReplacedEvent = function () {
        this.currentPage = getCurrentPage_1.default();
        switch (this.currentPage) {
            case pageTypes_1.PAGE_TYPES.default:
            case pageTypes_1.PAGE_TYPES.tribute:
                this.init();
                break;
            case pageTypes_1.PAGE_TYPES.home:
            case pageTypes_1.PAGE_TYPES.explore:
            default:
                this.disable();
                break;
        }
    };
    SmoothScroll.prototype.handleResizeEvent = function () {
        this.resetScrollArea();
    };
    SmoothScroll.prototype.handleFocusEvent = function () {
        if (document.documentElement.getAttribute("data-whatinput") === "keyboard") {
            this.disable();
        }
        else {
            this.handleContentReplacedEvent();
        }
    };
    SmoothScroll.prototype.update = function () {
        for (var key in this.renderedStyles) {
            this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
        }
        this.layout();
    };
    SmoothScroll.prototype.layout = function () {
        if (this.DOM !== undefined && this.DOM.scrollable !== null) {
            this.DOM.scrollable.style.transform = "translate(0," + -1 * this.renderedStyles.translationY.previous + "px)";
        }
    };
    SmoothScroll.prototype.setSize = function () {
        if (this.DOM !== undefined && this.DOM.scrollable !== null) {
            this.body.style.height = this.DOM.scrollable.clientHeight + "px";
        }
    };
    SmoothScroll.prototype.setMainStyle = function () {
        this.DOM.main.style.cssText = "position: fixed; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; z-index: 2";
        this.DOM.scrollable.style.willChange = "transform";
    };
    SmoothScroll.prototype.render = function () {
        for (var key in this.renderedStyles) {
            this.renderedStyles[key].current = this.renderedStyles[key].setValue();
            var prevValue = SmoothScroll.MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
            this.renderedStyles[key].previous = Number(prevValue.toFixed(5));
            if (this.renderedStyles[key].previous < .1) {
                this.renderedStyles[key].previous = this.renderedStyles[key].previous = 0;
            }
        }
        this.layout();
    };
    SmoothScroll.prototype.calcWinsize = function () {
        this.winsize = { width: window.innerWidth, height: window.innerHeight };
    };
    SmoothScroll.prototype.getPageYScroll = function () {
        if (this.DOM !== undefined && this.DOM.scrollable.clientHeight !== this.body.clientHeight) {
            this.setSize();
        }
        this.docScroll = scrollService_1.default.getScrollPosition();
    };
    SmoothScroll.MathUtils = {
        map: function (x, a, b, c, d) { return (x - a) * (d - c) / (b - a) + c; },
        // linear interpolation
        lerp: function (a, b, n) { return (1 - n) * a + n * b; }
    };
    return SmoothScroll;
}(HTMLElement));
exports.SmoothScroll = SmoothScroll;
customElements.define("smooth-scroll", SmoothScroll);
