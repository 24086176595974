"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ELEMENT_NAME = "share-link";
var ShareLink = /** @class */ (function (_super) {
    __extends(ShareLink, _super);
    function ShareLink() {
        var _this = _super.call(this) || this;
        _this.handleClickEvent = _this.handleClickEvent.bind(_this);
        return _this;
    }
    ShareLink.prototype.connectedCallback = function () {
        this.aElement = this.querySelector("a");
        if (this.aElement !== null) {
            this.href = this.aElement.getAttribute("href");
            if (this.href.indexOf("mailto:") === -1) {
                this.aElement.addEventListener("click", this.handleClickEvent);
            }
        }
    };
    ShareLink.prototype.disconnectedCallback = function () {
        if (this.aElement !== null) {
            this.aElement.removeEventListener("click", this.handleClickEvent);
        }
    };
    ShareLink.prototype.handleClickEvent = function (event) {
        event.preventDefault();
        this.windowPopup(this.href, 600, 450);
    };
    ShareLink.prototype.windowPopup = function (url, width, height) {
        var top = (screen.height - height) / 2;
        var left = (screen.width - width) / 2;
        var features = "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left;
        window.open(url, "", features);
    };
    return ShareLink;
}(HTMLElement));
customElements.define(ELEMENT_NAME, ShareLink);
