"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Returns result of querySelectorAll as an array.
 *
 * @param {string} selectors
 * @param {Document|Element} el [document]
 * @return {Element[]}
 */
function querySelectorAll(selectors, el) {
    if (el === void 0) { el = window.document; }
    var result = el.querySelectorAll(selectors);
    return Array.prototype.slice.call(result);
}
exports.default = querySelectorAll;
