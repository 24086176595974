"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Matches polyfill.
 *
 * @param {any} el
 * @param {string} selectors
 */
function matches(el, selectors) {
    if (el == null) {
        return false;
    }
    var fn = el.matches || el.msMatchesSelector;
    if (!fn) {
        console.warn("The matches() method is not available in this browser. Returned false");
        return false;
    }
    return fn.call(el, selectors);
}
exports.default = matches;
