"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var matches_1 = require("./matches");
/**
 * Return ancestor or self that matches `selectors`.
 *
 * @param {any} el
 * @param {string} selectors
 */
function ancestorOrSelf(el, selectors) {
    if (el == null) {
        return null;
    }
    return matches_1.default(el, selectors) ? el : ancestorOrSelf(el.parentElement, selectors);
}
exports.default = ancestorOrSelf;
