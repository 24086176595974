"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BLOCK_CLASS = "notification";
var ELEMENT_NAME = "mc-notification";
var TIMEOUT_SECONDS = 7;
var TYPE_ATTR_NAME = "type";
var McNotificationType;
(function (McNotificationType) {
    McNotificationType["Error"] = "error";
    McNotificationType["Info"] = "info";
    McNotificationType["Success"] = "success";
})(McNotificationType || (McNotificationType = {}));
var McNotification = /** @class */ (function (_super) {
    __extends(McNotification, _super);
    function McNotification() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    McNotification.open = function (message, type) {
        if (McNotification._current) {
            McNotification._current.close();
        }
        var button = document.createElement("button");
        button.classList.add(BLOCK_CLASS + "__close-button", "button", "button--text", "js-close");
        button.innerText = "Close";
        var content = document.createElement("div");
        content.classList.add(BLOCK_CLASS + "__content");
        content.innerHTML = message;
        content.appendChild(button);
        var notification = document.createElement(ELEMENT_NAME);
        notification.classList.add(BLOCK_CLASS);
        notification.appendChild(content);
        notification.type = type;
        document.body.appendChild(notification);
    };
    McNotification.error = function (message) {
        McNotification.open(message, McNotificationType.Error);
    };
    McNotification.info = function (message) {
        McNotification.open(message, McNotificationType.Info);
    };
    McNotification.success = function (message) {
        McNotification.open(message, McNotificationType.Success);
    };
    Object.defineProperty(McNotification.prototype, "type", {
        set: function (value) {
            this.setAttribute(TYPE_ATTR_NAME, value);
        },
        enumerable: true,
        configurable: true
    });
    McNotification.prototype.close = function () {
        clearTimeout(this._timeoutId);
        this.remove();
        McNotification._current = null;
    };
    McNotification.prototype.connectedCallback = function () {
        var _this = this;
        if (this.parentElement !== document.body) {
            document.body.appendChild(this);
            return;
        }
        if (McNotification._current) {
            McNotification._current.close();
        }
        this._closeButton = this._getCloseButton();
        McNotification._current = this;
        this._timeoutId = setTimeout(function () {
            _this.close();
        }, TIMEOUT_SECONDS * 1000);
        this.addEventListener("mouseenter", function () {
            clearTimeout(_this._timeoutId);
        });
    };
    McNotification.prototype._getCloseButton = function () {
        var _this = this;
        var button = this.querySelector(".js-close");
        if (!button) {
            return null;
        }
        button.addEventListener("click", function () {
            _this.close();
        });
        return button;
    };
    return McNotification;
}(HTMLElement));
exports.McNotification = McNotification;
customElements.define(ELEMENT_NAME, McNotification);
