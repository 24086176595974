"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var globalCssClasses_1 = require("../constants/globalCssClasses");
var reCaptchaService_1 = require("../services/reCaptchaService");
var scrollService_1 = require("../services/scrollService");
var querySelectorAll_1 = require("../utils/querySelectorAll");
var mcNotification_1 = require("./mcNotification");
var GenericForm = /** @class */ (function (_super) {
    __extends(GenericForm, _super);
    function GenericForm() {
        var _this = _super.call(this) || this;
        _this.isWorking = false;
        _this.getFormData = function () {
            var formData = new FormData(_this.form);
            var result = new FormData();
            formData.forEach(function (value, key) {
                // Remove `Image.` and `Text.` prefixes from form fields.
                var newKey = key.replace(/^\w+\./, "");
                result.append(newKey, value);
            });
            return result;
        };
        _this.setIsWorking = function (value) {
            _this.isWorking = value;
            if (_this.isWorking) {
                _this.classList.add(GenericForm.WORKING_CLASS);
            }
            else {
                _this.classList.remove(GenericForm.WORKING_CLASS);
            }
        };
        return _this;
    }
    Object.defineProperty(GenericForm.prototype, "successMessage", {
        get: function () {
            return this.getAttribute("success-message");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericForm.prototype, "errorMessage", {
        get: function () {
            return this.getAttribute("error-message");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericForm.prototype, "redirectOnUnauthorized", {
        get: function () {
            return Boolean(this.getAttribute("redirect-on-unauthorized"));
        },
        enumerable: true,
        configurable: true
    });
    GenericForm.prototype.connectedCallback = function () {
        var _this = this;
        this.form = this.querySelector("form");
        this.action = this.getAttribute("action") || this.form.getAttribute("action");
        this.method = this.getAttribute("method") || this.form.getAttribute("method");
        this.fields = querySelectorAll_1.default("form-field", this);
        this.dragDropInput = this.querySelector("drag-drop-input");
        this.reCaptchaTokenInput = this.querySelector(".js-recaptcha-token");
        this.reCaptchaAction = this.getAttribute("recaptcha-action") || "";
        this.formValidationSummaryEl = this.querySelector(".form__validation-summary");
        this.form.noValidate = true;
        this.form.addEventListener("submit", function (event) {
            event.preventDefault();
            if (_this.isWorking || !_this.validate()) {
                return;
            }
            _this.setIsWorking(true);
            if (_this.reCaptchaTokenInput) {
                reCaptchaService_1.default.ready().then(function (grecaptcha) {
                    grecaptcha.ready(function () {
                        try {
                            grecaptcha.execute(reCaptchaService_1.default.getSiteKey(), {
                                action: _this.reCaptchaAction
                            }).then(function (token) {
                                _this.reCaptchaTokenInput.value = token;
                                _this.submit();
                            });
                        }
                        catch (error) {
                            _this.onError(error);
                        }
                    });
                }).catch(function (error) {
                    _this.onError(error);
                });
            }
            else {
                _this.submit();
            }
        });
    };
    GenericForm.prototype.submit = function (action, method, onSuccess) {
        var _this = this;
        if (action === void 0) { action = null; }
        if (method === void 0) { method = null; }
        if (onSuccess === void 0) { onSuccess = null; }
        var options = {
            body: this.getFormData(),
            credentials: "same-origin",
            method: method || this.method
        };
        fetch(action || this.action, options)
            .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
            var error, modelStates, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 1];
                        return [2 /*return*/, response.json()];
                    case 1:
                        if (!(response.status === 401)) return [3 /*break*/, 2];
                        if (this.redirectOnUnauthorized) {
                            // Force a refresh and let the server handle redirection.
                            window.location.href = window.location.href;
                            return [2 /*return*/];
                        }
                        else {
                            throw new Error("The email address entered is not authorised to edit this tribute.");
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, response.json()];
                    case 3:
                        error = _a.sent();
                        modelStates = Object.values(error.ModelState);
                        errorMessage = error.Message + "\n\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t" + modelStates.map(function (modelState) {
                            return "<li>" + modelState.join(",") + "</li>";
                        }).join("") + "\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t";
                        throw new Error(errorMessage);
                    case 4: return [2 /*return*/];
                }
            });
        }); })
            .then(function (json) {
            onSuccess ? onSuccess(json) : _this.onSuccess(json);
        })
            .catch(function (error) {
            _this.onError(error);
        });
    };
    GenericForm.prototype.onError = function (error) {
        if (this.formValidationSummaryEl !== null) {
            this.formValidationSummaryEl.classList.add("form__validation-summary--visible");
            this.formValidationSummaryEl.innerHTML = "" + error;
            this.formValidationSummaryEl.setAttribute("role", "alert");
            scrollService_1.default.scrollTo(0);
        }
        this.setIsWorking(false);
        if (this.errorMessage) {
            mcNotification_1.McNotification.error(this.errorMessage);
        }
    };
    GenericForm.prototype.onSuccess = function (json, dontSwup) {
        if (dontSwup === void 0) { dontSwup = false; }
        if (this.formValidationSummaryEl !== null) {
            this.formValidationSummaryEl.classList.remove("form__validation-summary--visible");
            this.formValidationSummaryEl.innerHTML = "";
            this.formValidationSummaryEl.removeAttribute("role");
        }
        document.documentElement.classList.remove(globalCssClasses_1.GLOBAL_CSS_CLASSES.fixedWindow);
        if (dontSwup === false) {
            var url = window.location.pathname;
            if (window.swup) {
                swup.cache.remove(url);
                swup.loadPage({
                    url: url
                });
            }
            else {
                window.location.href = url;
            }
        }
        this.setIsWorking(false);
        if (this.successMessage) {
            mcNotification_1.McNotification.success(this.successMessage);
        }
    };
    GenericForm.prototype.validate = function () {
        var result = true;
        for (var _i = 0, _a = this.fields; _i < _a.length; _i++) {
            var page = _a[_i];
            if (!page.validate()) {
                result = false;
            }
        }
        if (this.dragDropInput !== null) {
            if (!this.dragDropInput.validate()) {
                result = false;
            }
        }
        return result;
    };
    GenericForm.WORKING_CLASS = "form--working";
    return GenericForm;
}(HTMLElement));
exports.GenericForm = GenericForm;
customElements.define("generic-form", GenericForm);
