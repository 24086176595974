"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var windowEventService_1 = require("../services/windowEventService");
var ancestorOrSelf_1 = require("../utils/ancestorOrSelf");
var mediaQueries_1 = require("../constants/mediaQueries");
var rotator_1 = require("../classes/rotator");
var gsap_1 = require("gsap");
var ELEMENT_NAME = "memory-image";
var ACTIVE_ATTR = "active";
var REVEAL_ATTR = "reveal";
var MemoryImage = /** @class */ (function (_super) {
    __extends(MemoryImage, _super);
    function MemoryImage() {
        var _this = _super.call(this) || this;
        _this.isHovering = false;
        _this.isTrackingHover = false;
        _this.isTransitioning = false;
        _this.hasInteracted = false;
        _this.handlingMouseMoveEvent = false;
        _this.containerEl = null;
        _this.rotation = new rotator_1.default();
        return _this;
    }
    MemoryImage.prototype.connectedCallback = function () {
        this.init();
    };
    MemoryImage.prototype.handleClickEvent = function () {
        this.toggleAttribute(ACTIVE_ATTR);
        if (this.hasInteracted === false) {
            this.hasInteracted = true;
        }
    };
    MemoryImage.prototype.handleMouseMoveEvent = function (event) {
        var _this = this;
        if (this.containerEl !== null && this.handlingMouseMoveEvent === false) {
            this.handlingMouseMoveEvent = true;
            var isHovering = ancestorOrSelf_1.default(event.target, "" + ELEMENT_NAME) === this;
            if (isHovering === true) {
                var rect = this.getBoundingClientRect();
                var maxAngle = 15; // Angle in degrees
                this.rotation.setNew(-maxAngle * ((event.clientY - rect.top - this.clientHeight / 2) / (this.clientHeight / 2)), maxAngle * ((event.clientX - rect.left - this.clientWidth / 2) / (this.clientWidth / 2)));
                if (this.isTrackingHover === false) {
                    this.isTrackingHover = true;
                    gsap_1.TweenLite.ticker.addEventListener("tick", this.updateContainerRotation);
                }
            }
            else {
                if (this.isTrackingHover === true) {
                    this.isTrackingHover = false;
                    gsap_1.TweenLite.ticker.removeEventListener("tick", this.updateContainerRotation);
                }
                if (this.rotation.x !== 0 && this.rotation.y !== 0 && this.isTransitioning === false) {
                    this.isTransitioning = true;
                    gsap_1.TweenLite.to(this.rotation, .5, {
                        x: 0,
                        y: 0,
                        newX: 0,
                        newY: 0,
                        onUpdate: this.updateContainerRotation,
                        onComplete: function () {
                            _this.isTransitioning = false;
                        }
                    });
                }
            }
            this.handlingMouseMoveEvent = false;
        }
    };
    MemoryImage.prototype.updateContainerRotation = function () {
        if (this.isTransitioning === false) {
            this.rotation.lerpToNew();
        }
        this.containerEl.style.transform = "rotateX(" + this.rotation.x + "deg) rotateY(" + this.rotation.y + "deg)";
    };
    MemoryImage.prototype.init = function () {
        var _this = this;
        this.handleMouseMoveEvent = this.handleMouseMoveEvent.bind(this);
        this.updateContainerRotation = this.updateContainerRotation.bind(this);
        this.containerEl = this.querySelector("." + ELEMENT_NAME + "__container");
        if (this.containerEl !== null) {
            this.updateContainerRotation();
        }
        if (window.matchMedia(mediaQueries_1.MEDIA_QUERIES.preferesReducedMotion).matches === false && "IntersectionObserver" in window && this.hasAttribute(REVEAL_ATTR) === true) {
            var iObserver = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        // iObserver.unobserve(this);
                        // iObserver.disconnect();
                        if (_this.hasInteracted === false && _this.hasAttribute(ACTIVE_ATTR) === true) {
                            _this.removeAttribute(ACTIVE_ATTR);
                        }
                        windowEventService_1.default.addMouseMoveEvent(_this.handleMouseMoveEvent);
                    }
                    else {
                        windowEventService_1.default.removeMouseMoveEvent(_this.handleMouseMoveEvent);
                    }
                });
            }, { threshold: 0.2 });
            iObserver.observe(this);
        }
        this.addEventListener("click", this.handleClickEvent.bind(this));
    };
    return MemoryImage;
}(HTMLElement));
customElements.define(ELEMENT_NAME, MemoryImage);
