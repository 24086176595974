"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var querySelectorAll_1 = require("../utils/querySelectorAll");
var accordionSection_1 = require("../classes/accordionSection");
var AccordionEl = /** @class */ (function (_super) {
    __extends(AccordionEl, _super);
    function AccordionEl() {
        return _super.call(this) || this;
    }
    AccordionEl.prototype.connectedCallback = function () {
        var sectionEls = querySelectorAll_1.default(".accordion__section", this);
        this.sections = sectionEls.map(function (section) { return new accordionSection_1.default(section); });
    };
    return AccordionEl;
}(HTMLElement));
exports.AccordionEl = AccordionEl;
customElements.define("accordion-el", AccordionEl);
