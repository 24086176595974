"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var getInteractionPoint_1 = require("../utils/getInteractionPoint");
var whichTransitionEvent_1 = require("../utils/whichTransitionEvent");
var ELEMENT_NAME = "mc-button";
var MODAL_TRIGGER_ATTR = "trigger-modal";
var McButton = /** @class */ (function (_super) {
    __extends(McButton, _super);
    function McButton() {
        var _this = _super.call(this) || this;
        _this.firstClick = true;
        _this.transitionEvent = whichTransitionEvent_1.default();
        _this.resetButtonTimeout = null;
        _this.modalEl = null;
        return _this;
    }
    McButton.prototype.connectedCallback = function () {
        this.init();
        this.handleTransitionEndEvent = this.handleTransitionEndEvent.bind(this);
        this.handleClickEvent = this.handleClickEvent.bind(this);
    };
    McButton.prototype.init = function () {
        this.buttonEl = this.querySelector(".button");
        if (this.buttonEl !== null) {
            this.pointEl = this.createPointElement();
            this.buttonEl.appendChild(this.pointEl);
            this.buttonEl.addEventListener("click", this.handleClickEvent.bind(this));
        }
        else {
            console.error("mc-button does not contain a `.button` child element");
        }
    };
    McButton.prototype.initModalTrigger = function () {
        if (this.hasAttribute(MODAL_TRIGGER_ATTR)) {
            var triggerModalVal = this.getAttribute(MODAL_TRIGGER_ATTR);
            this.modalEl = document.querySelector(triggerModalVal);
        }
    };
    McButton.prototype.handleClickEvent = function (event) {
        var _a = getInteractionPoint_1.default(this.buttonEl, event), x = _a.x, y = _a.y;
        this.pointEl.addEventListener(this.transitionEvent, this.handleTransitionEndEvent);
        this.animatePointElement(x, y);
        if (this.firstClick === true) {
            this.initModalTrigger();
        }
        if (this.modalEl !== null) {
            if (this.modalEl.modalActive === false) {
                this.modalEl.openOverlay();
            }
            else {
                this.modalEl.closeOverlay();
            }
        }
        this.firstClick = false;
    };
    McButton.prototype.handleTransitionEndEvent = function (event) {
        var _this = this;
        if (event.propertyName === "transform") {
            clearTimeout(this.resetButtonTimeout);
            this.resetButtonTimeout = setTimeout(function () {
                Object.assign(_this.pointEl.style, {
                    transform: "translate(-50%, -50%) scale(0)"
                });
            }, 2000);
            this.pointEl.removeEventListener(this.transitionEvent, this.handleTransitionEndEvent);
        }
    };
    McButton.prototype.createPointElement = function () {
        var el = document.createElement("span");
        Object.assign(el.style, {
            position: "absolute",
            display: "block",
            width: this.buttonEl.clientWidth + "px",
            height: this.buttonEl.clientWidth + "px",
            borderRadius: "50%",
            background: "#fff",
            opacity: ".4",
            transform: "translate(-50%, -50%) scale(0)",
            transformOrigin: "center",
            transition: "transform .25s ease-in-out",
            pointerEvents: "none",
            zIndex: -1
        });
        return el;
    };
    McButton.prototype.animatePointElement = function (x, y) {
        if (this.pointEl !== null) {
            Object.assign(this.pointEl.style, {
                top: y + "px",
                left: x + "px",
                transform: "translate(-50%, -50%) scale(2)"
            });
        }
        else {
            console.warn("No point element found for mc-button");
        }
    };
    return McButton;
}(HTMLElement));
customElements.define(ELEMENT_NAME, McButton);
