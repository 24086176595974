"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// https://developer.mozilla.org/en-US/docs/Web/Events/resize
var mediaQueries_1 = require("../constants/mediaQueries");
var removeFromArray_1 = require("../utils/removeFromArray");
var ResizeService = /** @class */ (function () {
    function ResizeService() {
        var callbacks = [];
        var width = window.innerWidth;
        var height = window.innerHeight;
        var resizeComplete;
        this.add = function (callback) {
            if (!callbacks.length) {
                window.addEventListener('orientationchange', resize);
                window.addEventListener('resize', resize);
            }
            addCallback(callback);
        };
        this.remove = function (callback) {
            callbacks = removeFromArray_1.default(callbacks, callback);
            if (!callbacks.length) {
                window.removeEventListener('orientationchange', resize);
                window.removeEventListener('resize', resize);
            }
        };
        this.isMobile = function () {
            return matchMedia(mediaQueries_1.MEDIA_QUERIES.mobile).matches === true;
        };
        this.isAboveMobile = function () {
            return matchMedia(mediaQueries_1.MEDIA_QUERIES.aboveMobile).matches === true;
        };
        this.isDesktop = function () {
            return matchMedia(mediaQueries_1.MEDIA_QUERIES.desktop).matches === true;
        };
        this.isTablet = function () {
            return matchMedia(mediaQueries_1.MEDIA_QUERIES.tablet).matches === true;
        };
        // fired on resize event
        function resize() {
            clearTimeout(resizeComplete);
            resizeComplete = setTimeout(function () {
                if (window.requestAnimationFrame) {
                    window.requestAnimationFrame(runCallbacks);
                }
                else {
                    setTimeout(runCallbacks, 66);
                }
            }, 250);
        }
        // run the actual callbacks
        function runCallbacks() {
            // Only run on width changes or if height change is greater than 150px
            if ((window.innerWidth != width || window.innerHeight != height)) {
                width = window.innerWidth;
                height = window.innerHeight;
                callbacks.forEach(function (callback) {
                    callback();
                });
            }
        }
        // adds callback to loop
        function addCallback(callback) {
            if (callback && callbacks.indexOf(callback) === -1) {
                callbacks.push(callback);
            }
        }
    }
    return ResizeService;
}());
var resizeService = new ResizeService();
exports.default = resizeService;
