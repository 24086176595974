"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var swiper_1 = require("swiper");
var querySelectorAll_1 = require("../utils/querySelectorAll");
var mediaQueries_1 = require("../constants/mediaQueries");
var resizeService_1 = require("../services/resizeService");
var ELEMENT_NAME = "image-swiper";
var ImageSwiper = /** @class */ (function (_super) {
    __extends(ImageSwiper, _super);
    function ImageSwiper() {
        var _this = _super.call(this) || this;
        _this.swiper = null;
        _this.swiperNavigation = _this.createNav();
        return _this;
    }
    ImageSwiper.prototype.connectedCallback = function () {
        this.swiperSlides = querySelectorAll_1.default(".swiper-slide", this);
        this.init = this.init.bind(this);
        this.init();
        resizeService_1.default.add(this.init);
    };
    ImageSwiper.prototype.disconectedCallback = function () {
        resizeService_1.default.remove(this.init);
    };
    ImageSwiper.prototype.createNav = function () {
        var navigationWrapper = document.createElement("div");
        navigationWrapper.className = ELEMENT_NAME + "__nav";
        var prevButton = this.createNavButton("Previous", "prev");
        var nextButton = this.createNavButton("Next", "next");
        navigationWrapper.appendChild(prevButton);
        navigationWrapper.appendChild(nextButton);
        return {
            navigationWrapper: navigationWrapper,
            prevButton: prevButton,
            nextButton: nextButton
        };
    };
    ImageSwiper.prototype.createNavButton = function (buttonText, buttonCssModifier) {
        var button = document.createElement("button");
        button.innerHTML = "\n\t\t\t\t<span class=\"audio-only\">" + buttonText + "</span>\n\t\t\t\t<svg class=\"icon icon--chevron\" viewBox=\"0 0 17 30\" width=\"17\" height=\"30\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1 0l15 15L1 30\" stroke=\"currentColor\" fill=\"none\"/></svg>\n\t\t\t";
        button.className = ELEMENT_NAME + "__nav-item " + ELEMENT_NAME + "__nav-item--" + buttonCssModifier + " button-reset";
        return button;
    };
    ImageSwiper.prototype.init = function () {
        var slidesWidth = 0;
        this.swiperSlides.forEach(function (slide) { return slidesWidth += slide.clientWidth; });
        if (slidesWidth > this.clientWidth) {
            if (this.swiper === null) {
                var options = {
                    slidesPerView: "auto",
                    grabCursor: true,
                    navigation: {
                        prevEl: this.swiperNavigation.prevButton,
                        nextEl: this.swiperNavigation.nextButton,
                        disabledClass: ELEMENT_NAME + "__nav-item--disabled",
                    },
                    threshold: 20,
                    a11y: {
                        notificationClass: "audio-only"
                    }
                };
                if (window.matchMedia(mediaQueries_1.MEDIA_QUERIES.aboveMobile).matches === true) {
                    this.prepend(this.swiperNavigation.navigationWrapper);
                }
                this.swiper = new swiper_1.default(this, options);
            }
            else {
                if (this.contains(this.swiperNavigation.navigationWrapper) === false && window.matchMedia(mediaQueries_1.MEDIA_QUERIES.aboveMobile).matches === true) {
                    this.prepend(this.swiperNavigation.navigationWrapper);
                }
                else {
                    if (window.matchMedia(mediaQueries_1.MEDIA_QUERIES.aboveMobile).matches === false) {
                        this.swiperNavigation.navigationWrapper.remove();
                    }
                }
            }
        }
        else {
            if (this.swiper !== null) {
                this.swiper.destroy(true, true);
                this.swiperNavigation.navigationWrapper.remove();
                this.swiper = null;
            }
        }
    };
    return ImageSwiper;
}(HTMLElement));
customElements.define(ELEMENT_NAME, ImageSwiper);
