"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var gsap_1 = require("gsap");
var querySelectorAll_1 = require("../utils/querySelectorAll");
var formUtils_1 = require("../utils/formUtils");
var reCaptchaService_1 = require("../services/reCaptchaService");
var AddTributeForm = /** @class */ (function (_super) {
    __extends(AddTributeForm, _super);
    function AddTributeForm() {
        var _this = _super.call(this) || this;
        _this.isWorking = false;
        _this.pageIndex = 0;
        _this.validate = function () {
            var result = true;
            for (var _i = 0, _a = _this.pages; _i < _a.length; _i++) {
                var page = _a[_i];
                if (!page.validate()) {
                    result = false;
                }
            }
            return result;
        };
        _this.setActivePage = function (noTransition) {
            if (noTransition === void 0) { noTransition = false; }
            var pageToShow = null;
            var pagesToHide = [];
            for (var index = 0; index < _this.pages.length; index += 1) {
                var page = _this.pages[index];
                if (index === _this.pageIndex) {
                    pageToShow = page;
                }
                else {
                    pagesToHide.push(page);
                }
            }
            if (noTransition === true) {
                pageToShow.classList.add(AddTributeForm.ACTIVE_PAGE_CLASS);
                pagesToHide.forEach(function (page) { return page.classList.remove(AddTributeForm.ACTIVE_PAGE_CLASS); });
                if (_this.smoothScrollEl !== null) {
                    _this.smoothScrollEl.resetScrollArea();
                }
            }
            else {
                var tl = new gsap_1.TimelineLite();
                tl.to(pagesToHide, .4, {
                    opacity: 0, onComplete: function () {
                        pagesToHide.forEach(function (page) {
                            page.classList.remove(AddTributeForm.ACTIVE_PAGE_CLASS);
                        });
                        pageToShow.classList.add(AddTributeForm.ACTIVE_PAGE_CLASS);
                    }
                });
                tl.fromTo(pageToShow, .6, { opacity: 0 }, {
                    opacity: 1, ease: gsap_1.Power0.easeNone, onComplete: function () {
                        pageToShow.focus();
                    }
                });
            }
        };
        _this.setFirstName = function () {
            var firstName = _this.firstNameInput.value;
            for (var _i = 0, _a = _this.firstNameElements; _i < _a.length; _i++) {
                var el = _a[_i];
                el.innerText = firstName;
            }
        };
        _this.setIsWorking = function (value) {
            _this.isWorking = value;
            if (_this.isWorking) {
                _this.classList.add(AddTributeForm.WORKING_CLASS);
            }
            else {
                _this.classList.remove(AddTributeForm.WORKING_CLASS);
            }
        };
        return _this;
    }
    AddTributeForm.prototype.connectedCallback = function () {
        var _this = this;
        this.action = this.getAttribute("action");
        this.form = this.querySelector("form");
        this.firstNameInput = this.querySelector(".js-first-name-input input");
        this.firstNameElements = querySelectorAll_1.default(".js-first-name", this);
        this.method = this.getAttribute("method");
        this.pages = querySelectorAll_1.default("form-page", this);
        this.reCaptchaTokenInput = this.querySelector(".js-recaptcha-token");
        this.formValidationSummaryEl = this.querySelector(".form__validation-summary");
        this.smoothScrollEl = document.querySelector("smooth-scroll");
        if (this.firstNameInput) {
            this.firstNameInput.addEventListener("change", function () {
                _this.setFirstName();
            });
            this.setFirstName();
        }
        this.addEventListener("click", function (event) {
            var target = event.target;
            if (target.classList.contains("js-previous")) {
                _this.changePage(-1);
            }
            if (target.classList.contains("js-next")) {
                _this.changePage(1);
            }
        });
        this.setActivePage(true);
        this.form.noValidate = true;
        this.form.addEventListener("submit", function (event) {
            event.preventDefault();
            if (!_this.validate()) {
                return;
            }
            if (_this.isWorking) {
                return;
            }
            _this.setIsWorking(true);
            reCaptchaService_1.default.ready().then(function (grecaptcha) {
                grecaptcha.ready(function () {
                    try {
                        grecaptcha.execute(reCaptchaService_1.default.getSiteKey(), {
                            action: "addtributeform_submit"
                        }).then(function (token) {
                            _this.reCaptchaTokenInput.value = token;
                            _this.submit();
                        });
                    }
                    catch (error) {
                        _this.onError(error);
                    }
                });
            }).catch(function (error) {
                _this.onError(error);
            });
        });
    };
    AddTributeForm.prototype.changePage = function (change) {
        var newPageIndex = this.pageIndex + change;
        if (newPageIndex < 0 || newPageIndex >= this.pages.length) {
            return false;
        }
        if (newPageIndex > this.pageIndex) {
            var currentPage = this.pages[this.pageIndex];
            var isValid = currentPage.validate();
            if (!isValid) {
                return false;
            }
        }
        this.pageIndex = newPageIndex;
        this.setActivePage();
        return true;
    };
    AddTributeForm.prototype.submit = function () {
        var _this = this;
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var options = {
            body: formUtils_1.getFormDataAsJsonString(this.form),
            headers: headers,
            method: this.method
        };
        fetch(this.action, options)
            .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
            var json, error, modelStates, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        json = response.json();
                        if (!response.ok) return [3 /*break*/, 1];
                        return [2 /*return*/, json];
                    case 1: return [4 /*yield*/, json];
                    case 2:
                        error = _a.sent();
                        modelStates = Object.values(error.ModelState);
                        errorMessage = error.Message + "\n\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t" + modelStates.map(function (modelState) {
                            return "<li>" + modelState.join(",") + "</li>";
                        }).join("") + "\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t";
                        throw new Error(errorMessage);
                }
            });
        }); })
            .then(function (json) {
            _this.onSuccess(json);
        })
            .catch(function (error) {
            _this.onError(error);
        });
    };
    AddTributeForm.prototype.onError = function (error) {
        if (this.formValidationSummaryEl != null) {
            this.formValidationSummaryEl.classList.add("form__validation-summary--visible");
            this.formValidationSummaryEl.innerHTML = "" + error;
            this.formValidationSummaryEl.setAttribute("role", "alert");
        }
        this.setIsWorking(false);
    };
    AddTributeForm.prototype.onSuccess = function (json) {
        if (this.formValidationSummaryEl !== null) {
            this.formValidationSummaryEl.classList.remove("form__validation-summary--visible");
            this.formValidationSummaryEl.innerHTML = "";
            this.formValidationSummaryEl.removeAttribute("role");
        }
        if (window.swup) {
            swup.loadPage({
                url: json.NewUrl
            });
            this.setIsWorking(false);
        }
        else {
            window.location.href = json.NewUrl;
        }
    };
    AddTributeForm.ACTIVE_PAGE_CLASS = "form__page--active";
    AddTributeForm.WORKING_CLASS = "add-tribute-form--working";
    return AddTributeForm;
}(HTMLElement));
customElements.define("add-tribute-form", AddTributeForm);
